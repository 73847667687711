
import { useState } from "react";
import {Link,useLocation, useNavigate,useParams } from "react-router";
//import useAuth from "../../Hooks/UseAuth";
import "./Recovery.css"
// import ClipLoader from "react-spinners/ClipLoader";
//import axios from '../../api/axios';
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import { axiosPrivate } from "../../api/axios";
// import { useNavigate, useParams } from 'react-router';

// import { Link,useLocation,useNavigate } from "react-router-dom";

function Login(){  
    
    let navigate = useNavigate();
    let location = useLocation();
	const [email, setEmail] = useState('');
    const [name, setName] = useState('')
	const [password, setPassword] = useState(''); 
	const [passwordsec, setPasswordsec] = useState(''); 
    //const {setAuth, auth} = useAuth()
    const [signUp, setSignup] = useState(false); 
    const [loginState, setLogin] = useState(true); 
    const [forgotPwdState, setForgotPwdStaten] = useState(false); 
    const [sucessRecovery, setSucessRecovery] = useState(false); 
    const [register, setRegister] = useState(false);
    const [Branch, setBranch] = useState("");
    const [AccNo, setAccNo] = useState("");
    const [Loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [Msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);
    const LOGIN_URL = '/auth';
    const BASE_URLLOCAL ="http://localhost:2000/"
    const BASE_URLONLINE ="https://superagentback.onrender.com"
    let {token} = useParams();

    //
   

    

    const handleAuthForm = () =>{
          
          setLogin(!loginState); 
          setRegister(!register);
          
    }
    const handleFOrgotPwd = () =>{
          setForgotPwdStaten(true)
          setLogin(false); 
          setRegister(false);
          
    }

  
   
    const handleSubmit = async (e) => {
        // e.preventDefault();
        e.preventDefault();  
        setLoading(true)
        setSuccessMsg(false);
        setErrorMsg(false);
        console.log(token)
       
        const from = location?.state?.from?.pathname || "/";

        try {
            console.log("pwd2",email,"and",password)
            const response = await axiosPrivate.post(`/auth/UsrRecoveryToken/${token}`,
            // const response = await axios.post(LOGIN_URL+"/UsrRecoveryToken/:token",
                JSON.stringify({ password }),
                // JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Credentials': 'true' ,
                    // 'Origin': 'https://www.google.com/'
                    // 'Origin': 'http://localhost:3000'
                 },
                    
                    withCredentials: true,
                    crossDomain: true,
            
                }
            );
            if(response?.data?.accessToken !=""){

                console.log(JSON.stringify(response));
                // const user = response?.data?.email;
                // const accessToken = response?.data?.accessToken;
                // const roles = response?.data?.roles;
                // const foundUser = response?.data?.foundUser;
                // setAuth({});
                // setAuth({ user, roles : [roles,"test role"], accessToken, foundUser});
                // setAuth({ user, pwd, roles, accessToken });
               // console.log("autheeeeee",auth)
                setLoading(false)
           
            alert("Password Changed!");
            navigate(from,{replace: true});

            }
            
            // navigate(from, { replace: true });
        } catch (err) {
           
        }
    }

   

    


    return( 

        <>  
            <div className="center">
                {loginState &&<>

                    <h1>Account Recovery</h1>
                        <form onSubmit={handleSubmit}>
                            {/* <div className="txt_field">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                // placeholder="Email"
                                required
                            />
                            <span></span>
                            <label>Email</label>

                            </div> */}
                            <div  className="txt_field">

                                    <input
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            // placeholder="Password"
                                            required
                                        />

                                    <span></span>
                                    <label>Password</label>

                            </div>

                            
                                
                           {/* Loading Animation */}
                            {Loading ?
                            <div>
                                <BeatLoader color="#36d7b7" />
                              
                            </div> :  
                                <>
                                     <input type="submit" value="Reset Password" />
                                     {errorMsg && <div className="errorMsgContainer">
                                            
                                            <p>{`Operation Failed! ${Msg}`}</p>
                                            
                                 </div>}
                                        {/* <div className="signup_link">
                                        Not a member? <a href="#" onClick={handleAuthForm}>Signup</a>
                                    </div>

                                    <div className="signup_link">
                                          <a href="#" onClick={handleFOrgotPwd}>Forgot your password?</a>
                                    </div> */}

                                </>   
                             
                             } 

                           
                           
                        </form>

                </>}
              
			
		</div>
      


        </>
    )

}

export default Login;