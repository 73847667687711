
function NavBar(){  
    return <>
    <div className="wholeHome">
        <h1>In Home</h1> 

     
        </div>
        
    </>

}

export default NavBar;

// function NavBar(){  
//     return <>
//     <div className="wholeHome">
//         <h1>In Home</h1> 

     
//         </div>
        
//     </>

// }

// export default NavBar;