




import { Link } from "react-router-dom";
import "./Prelude.css"



function Prelude() {


    
    
  return (

        <div className="PreludeCover">

                    <h2>We bring you the chizziest hot Deals!</h2>
                    <p>Our best selling deals right here</p>
        </div>
  );
}

export default Prelude;
