
import "./SearchBar.css"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faChartLine,faTools,faComments,faLocation,faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { Link } from "react-router-dom";
import useAuth from "../../Hooks/UseAuth";


function SearchBar(){
const auth =  useAuth();
console.log("deeeee",auth)
    return (
        <>  
                <div className='DelNavCOver'> 
        <div className='fistHalf'>

        <div className='navLogo'>
                <h2>
                    
                    <Link className='textLink' to="/">Ntumai</Link>

                </h2>

            </div>
            <div className='DelAddress'>
                <div>
                 <FontAwesomeIcon icon={faLocationPin} />
                </div>
                <div className='words'>
                    <p>Delivery Address</p>
                    <h3>Select an Address</h3>

                </div>

            </div>
            {/* <div className='DelSearch'>
             
                                    <input 
                                     placeholder="Enter your Address"
                                    
                                    ></input>
                                    <button>
                                        Order Now
                                    </button>

                           

            </div> */}

        </div>
        {auth?
        // <p>{String(auth?.user).substring(0,1)}</p>:
        <p>{auth.auth.user}</p>:
           
            <div className='LoginBtn'>
                <button>Login/Register</button>

            </div>}

      </div>
        
        </>
    )


}

export default SearchBar