

import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faChartLine,faTools,faComments } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import "./Business.css"
import img1 from "../../Components/images/img1.jpg";
import img2 from "../../Components/images/img1.jpg";
import img3 from "../../Components/images/img1.jpg";
import SearchBar from '../../Components/SearchBar/SearchBar';
import Footer from '../../Components/Footer/Footer';
import FAQ from '../../Components/FAQ/FAQ';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LogoImg from "../../Components/images/ntumaiLogo.png"
import useAuth from "../../Hooks/UseAuth";
// import "./Hero.css"
// function Hero(){  

    
// import img1 from "../images/img1.jpg"
// import img2 from "../images/img1.jpg"
// import img3 from "../images/img1.jpg"
// function FAQ(){  

import { useNavigate, useParams } from 'react-router';
import SlideCarousel from '../../Components/SlideCarouselSec/SlideCarousel';
import Listing from '../../Components/StoreListing/StoreListing';
import RelatedStore from '../../Components/RelatedStore/RelatedStore';
import ScrollToTop from '../../Components/Scrolltop/Scrolltop';
import LogoImg1 from "../../Components//images/img1.jpg"


import useContentful, {getPartners} from '../../Hooks/Contentful'
import Navbar from '../../Components/NavSec/NavSec';
// function Blog() { 




function Business(){  


let {BID} = useParams();

 const [DAddress,setDAddress] = useState("")
 const [current,setCurrent] = useState(BID !=undefined ? BID:2);
 //useState(1)
 const {auth} = useAuth()
 const{getPartners} = useContentful()

 

 //const [bid, setBid] = useState(BID !=undefined?BID:1);


 const [cardDataSec, setCardDataSec] = useState()

 useEffect(() => {
         console.log("Hello")
      // getAuthors().then((response) => console.log("ressedagt",response));
       var wait = getPartners().then((response) => response && setCardDataSec(response));

    //    if(wait){
    //     console.log("Sec",cardDataSec)
    //    }
         
     },[]);
 


// function Offers(){  

    const cardData = [ 
        { 
           ImgUrl: img1,
           Name: "Snowflake chartels", 
           Title: "Nike Converse",
           Price: 23,
           ID : 2
     
        },
        { 
           ImgUrl: img2,
           Name: "Zed Watch guy", 
           Title: "Timex Classic",
           Price: 12,
           ID : 1
     
        }, 
         { 
           ImgUrl: img3,
           Name: "Ntumai", 
           Title: "Tasks and apointments",
           Price: 45,
           ID : 3
     
        }, 
     
     
       ]

       const store = [
        {
            Name : "Snowflake chartels",
            Location : "Lusaka",
            OpsHrs : "08:00AM to 5PM",
            ID : "1",
            CoverImg : ""

        },
        {
            Name : "Zed Watch Guy",
            Location : "Lusaka",
            OpsHrs : "08:00AM to 5PM",
            ID : "2",
            CoverImg : ""

        },
        {
            Name : "Ntumai",
            Location : "Lusaka",
            OpsHrs : "06:00AM to 7PM",
            ID : "3",
            CoverImg : ""

        },
       ]
     

    //    useEffect(()=>{
    //        getPartners()
    //    },[])

    return <> 
             {/* <SearchBar/> */}
             <ScrollToTop/>
             <div>
             <div className="NavSecContainerProd">
               <Navbar/>
                    {/* <div className="loggedHeader">
                    <div className="Logo" >
                    <Link className="textLink" to="/"><img src={LogoImg} /></Link>
                        
                    </div>
                    <div className="SignIn">
                        <h1>{String(auth?.foundUser?.name).substring(0,1)}</h1>
                      
                    </div>
                    
                  </div> */}

                  </div>
             {/* <div className="NavBar">  

                 
                    <div className="Logo"
                    
                    >
                               
                                <Link to={"/"}>
                                <img src={LogoImg} />
                                </Link>
                                
                    </div>
                   

                    <div className="NavItems">
                        {auth.foundUser?
                        <div className="loggedBtn"> 
                            <button >
                                    {String(auth?.foundUser?.name).substring(0,1)}
                            </button></div>
                            
                            :

                            <button> 
                                <Link className="textLink" to="Order">Login/Sign up</Link>
                            

                            </button>

                        }
                        
                    </div>

           </div> */}

             </div>
             <div className='indBusinessCover'>

                    <div
                   
                    
                         style={{ backgroundImage: `url(${cardDataSec?.find(item => item.id == current)?.logo.fields.file.url|| LogoImg1})`,
                             
                         }}
                    
                    className='BusiCoverImg'>

                        { cardDataSec  && cardDataSec.map((item,key)=>{
                               
                            //    console.log("cardsec",cardDataSec)
                            //    console.log("cardsecLogo",cardDataSec?.[0]?.logo.fields.file.url)
                               
                            return(
                                <>  
                                {item.id==current && <> 
                                      
                                      

                                       <div className='Bforeword'>

                                        <div className='Header'>

                                            <h1>{item.name}</h1>
                                        </div>
                                        <div className='Reviews'>
                                            <b><p>4428 Reviews</p></b>
                                        </div>
                                        <div className='Location'>
                                        <b><p>{item.location}</p></b>
                                        </div>
                                        <div className='OpHours'>
                                        {/* <h3>Open</h3>    */}
                                        <b><p>{`Open ${item.openningHourTrue}hrs to ${item.closingHourTrue}hrs |`}</p> </b> 
                                        <button>{item.category.content[0].data.target.fields.description}</button>
                                        </div>
                                        </div> 
                                
                                </>  }
                                     
                        
                                 </>

                            )
                            
                       




                        })

                           
                        }

                            


                    </div>
                    {/* <Listing filter={"Zed Clothes Line"}/> */}
                    {cardDataSec && (
                        <Listing filter={cardDataSec.find(item => item.id == current)?.name} />
                    )}
                                    {/* <Listing filter={cardDataSec?.find(item => item.id == current)?.name} /> */}

                    <RelatedStore/>

                    <Footer/>

                    {/* <div className='BServicesCover'>
                        <div className='BservicesSplitfirst'>
                            <div className='serviceRedirects'>
                                <button>Write a review</button>
                                <button>Share</button>
                                <button>Save</button>
                           </div>
                        
                        <div className='Bservices'>
                            <div className="stores"> 

                                    <div className="storeForeword">
                                        <div className="normText"><b><p>Popular Orders</p></b></div>
                                          

                                    </div>
                                    <div className="StoreList">

                                            <div className="Cardsss">
                                    
                                        {  
                                        cardData.map((item,index)=>{ 

                                              return( 
                                           <>
                                                
                                                {item.ID ==current && <>  
                                                <div className="CardHolderSec" key={index}> 

                                                <div className="ImageHolder">
                                                    <img src={item.ImgUrl}/> 
                                                
                                                    </div>

                                                    <div className="ImageText">  

                                                            <div className="firstLine">
                                                                    <h3>{item.Name} </h3>  
                                                                    <p>{`ZMK ${item.Price}`}</p>
                                                            </div>
                                                    
                                                    
                                                    <p> {item.Title}</p>

                                                    </div>  
                                                    <div className="specialOrder">  
                                                                    
                                                                    <button>
                                                                        <Link className="textLink" to={"/order"} >Order Now</Link>
                                                                        
                                                                    </button>

                                                            </div>
                                                                                                            
                                                

                                                               

                                                
                                                </div>

                                                </>}    

                                                </>
                                            )

                                        }) 
                                    }
                                    
                                        </div>
                                    

                                    </div>
                                

                                
                                    </div>

                        </div>


                        </div>
                        <div className='BservicesSplitSec'>
                            <div className='cardHeader'>
                                <h2>Order Food</h2>
                            </div>
                            <div className='serviceTag'>
                                <p>Delivery</p>
                            </div>
                            <div className='serviceSubmit'>
                                    <div className='servInput'>
                                        <input placeholder='Delivery Address' value={DAddress} onChange={(e)=> setDAddress(e.target.value)}></input>
                                    </div>
                                    <div className='servBtn'>
                                        <button>Start Order</button>
                                    </div>
                            </div>

                        </div>
                        
                    </div> */}

             </div>
             {/* <FAQ/>
             <Footer/> */}
   
    </>

}

export default Business;