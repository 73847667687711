import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faChartLine,faTools,faComments,faLocation,faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import "./Delivery.css"
import img1 from "../../Components/images/img1.jpg";
import Footer from '../../Components/Footer/Footer';

import FAQ from '../../Components/FAQ/FAQ';
import { Link } from 'react-router-dom';
import SearchBar from '../../Components/SearchBar/SearchBar';

// function FAQ(){  




function Delivery(){  
    return <>
      
      {/* <div className='DelNavCOver'> 
        <div className='fistHalf'>

        <div className='navLogo'>
                <h2>
                    
                    <Link className='textLink' to="/">Ntumai</Link>

                </h2>

            </div>
            <div className='DelAddress'>
                <div>
                 <FontAwesomeIcon icon={faLocationPin} />
                </div>
                <div className='words'>
                    <p>Delivery Address</p>
                    <h3>Select an Address</h3>

                </div>

            </div>
            <div className='DelSearch'>
             
                                    <input 
                                     placeholder="Enter your Address"
                                    
                                    ></input>
                                    <button>
                                        Order Now
                                    </button>

                           

            </div>

        </div>
           
            <div className='LoginBtn'>
                <button>Login/Register</button>

            </div>

      </div> */}
      <SearchBar/>

      <div className='delFilter'>
        <button>Cocktail</button>
        <button>Vegetables</button>
        <button>Breakfast</button>
        <button>Dinner</button>
        <button>Lunch</button>

      </div>

      <div className='delResCover'>

      <div className='delResult'>
         <div className='resImg'>
            <img src={img1} />

         </div>
         <div className='resDetails'>
            <div className='storeHeader'>
                <h1>Pizza Hut</h1>
            </div>
            <div className='Location'>
                <p>Lusaka</p>
            </div>
            <div className='OpsHrs'>
                <p>08:00pm</p>
            </div>
            <div className='viewBusiness'>
                <button><Link className="textLinkz" to="/Business">View Business</Link></button>
            </div>

         </div>
      </div>

      <div className='delResult'>
         <div className='resImg'>
            <img src={img1} />

         </div>
         <div className='resDetails'>
            <div className='storeHeader'>
                <h1>Pizza Hut</h1>
            </div>
            <div className='Location'>
                <p>Lusaka</p>
            </div>
            <div className='OpsHrs'>
                <p>08:00pm</p>
            </div>
            <div className='viewBusiness'>
                <button><Link className="textLinkz" to="/Business">View Business</Link></button>
            </div>

         </div>
      </div>

      <div className='delResult'>
         <div className='resImg'>
            <img src={img1} />

         </div>
         <div className='resDetails'>
            <div className='storeHeader'>
                <h1>Pizza Hut</h1>
            </div>
            <div className='Location'>
                <p>Lusaka</p>
            </div>
            <div className='OpsHrs'>
                <p>08:00pm</p>
            </div>
            <div className='viewBusiness'>
                <button><Link className="textLinkz" to="/Business">View Business</Link></button>
            </div>

         </div>
      </div>
      <div className='delResult'>
         <div className='resImg'>
            <img src={img1} />

         </div>
         <div className='resDetails'>
            <div className='storeHeader'>
                <h1>Pizza Hut</h1>
            </div>
            <div className='Location'>
                <p>Lusaka</p>
            </div>
            <div className='OpsHrs'>
                <p>08:00pm</p>
            </div>
            <div className='viewBusiness'>
                <button><Link className="textLinkz" to="/Business">View Business</Link></button>
            </div>

         </div>
      </div>

      </div>

      <FAQ/>  
      <Footer/>

      

        
    </>

}

export default Delivery;