// src/Pages/Cart/Cart.js
import React from 'react';
import './Cart.css'; // Import your CSS for styling
import Navbar from '../../Components/NavSec/NavSec';
import Footer from '../../Components/Foooter/Footer';

const Cart = ({ cartItems, setCartItems }) => {
  
  const handleRemoveItem = (id) => {
    setCartItems(cartItems.filter(item => item.ID !== id));
  };

  const handleClearCart = () => {
    setCartItems([]);
  };

  return (

             <>

                <div className='NavSecContainerProds'>
                      <Navbar/>
                </div>
                  
                    <div className="cart-container">

                   
                    <h1>Your Cart</h1>
                    {cartItems.length === 0 ? (
                    <p>Your cart is empty.</p>
                    ) : (
                    <>
                    <ul>
                        {cartItems.map(item => (
                        <li key={item.ID} className="cart-item">
                            <h2>{item.Name}</h2>
                            <p>Price: <b>ZMK {item.Price}</b></p>
                            <p>Quantity: {item.count}</p>
                            <button onClick={() => handleRemoveItem(item.ID)}>Remove</button>
                        </li>
                        ))}
                    </ul>
                    <button className="clear-cart" onClick={handleClearCart}>
                        Clear Cart
                    </button>
                    </>
                    )}
                    </div>

                    <Footer/>
             </>
    
  );
};

export default Cart;
