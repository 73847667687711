import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUsers, faCogs, faHome } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { axiosPrivate } from "../../api/axios";
import LogoImg from "../../Components/images/ntumaiLogo.png";
import { Link } from 'react-router-dom';
import './AdminView.css';

function AdminView() {
  const [Tasks, setTasks] = useState([{}]);
  const [statusValue, setstatusValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get('/Orders');
        if (response?.data) {
          setTasks(response.data.propdata);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUsers();
  }, []);

  const handleCartClick = (item) => {
    setSelectedCart(item?.Cart || []);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    setstatusValue(e.target.value);
  };

  async function handleSubmit(e, item) {
    e.preventDefault();
    const response = await axiosPrivate.post("/Orders/AdmnOrders", JSON.stringify({
      statusValue,
      ID: item
    }), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      crossDomain: true,
    });

    if (response?.data?.status === 'Ok') {
      try {
        const response = await axiosPrivate.get('/Orders');
        if (response?.data) {
          setTasks(response.data.propdata);
          setstatusValue("Update Status");
          alert("Status updated Successfully");
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  return (
    <div className="adminContainer">
      <div className="SideBar">
        <ul>
          <li><FontAwesomeIcon className="fontAwesomeItem" icon={faChartLine} /> Orders</li>
          <li><FontAwesomeIcon className="fontAwesomeItem" icon={faCogs} /> Merchants</li>
          <li><FontAwesomeIcon className="fontAwesomeItem" icon={faUsers} /> Customers</li>
          <li><FontAwesomeIcon className="fontAwesomeItem" icon={faHome} /> Team</li>
        </ul>
      </div>

      <div className="outlet">
        <div className="Heading">
          <h1>Admin Dashboard</h1>
        </div>

        <div className="Table">
          <table className="contentTable">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Status</th>
                <th>Set Status</th>
                <th>Date</th>
                <th>Cart</th>
                <th>Start</th>
                <th>Destination</th>
                <th>Carrier</th>
                <th>Customer Name</th>
                <th>Phone</th>
                <th>Meters</th>
                <th>Minutes</th>
              </tr>
            </thead>
            <tbody>
              {Tasks && Tasks.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
                .map((item, index) => {
                  const isod = new Date(item?.OrderDate);
                  return (
                    <tr key={item?._id}>
                      <td>{index + 1}</td>
                      <td>{item.Status}</td>
                      <td>
                        <select value={statusValue} name="cars" onChange={handleChange}>
                          <option value="Pending">Update Status</option>
                          <option value="Pending">Pending</option>
                          <option value="Driver Assigned">Driver Assigned</option>
                          <option value="Deliverying">Deliverying</option>
                          <option value="Completed">Completed</option>
                          <option value="Cancelled">Cancelled</option>
                        </select>
                      </td>
                      <td>{isod?.toString().substring(3, 15)}</td>
                      <td>
                        {item?.Cart?.length > 0
                          ? <button onClick={() => handleCartClick(item)}>
                              View ({item?.Cart?.length})
                            </button>
                          : "Empty"
                        }
                      </td>
                      <td>{item?.startAddress}</td>
                      <td>{item?.DAddress}</td>
                      <td>{item?.carrier}</td>
                      <td>{item?.name}</td>
                      <td>{item?.PhoneNumber}</td>
                      <td>{item?.distance}</td>
                      <td>{item?.duration}</td>
                      <td>
                        <button onClick={(e) => handleSubmit(e, item?._id)}>
                          Update Status
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Cart Items */}
      {showModal && (
        <div className="modal">
          <div className="modalContent">
            <button className="closeButton" onClick={handleCloseModal}>X</button>
            <h3>Cart Items</h3>
            <div className="modalBody">
              {selectedCart.length === 0 ? (
                <p>No items in the cart</p>
              ) : (
                <>
                  <table className="modalTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Count</th>
                        <th>Price (ZMK)</th>
                        <th>Location</th>
                        <th>ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCart.map((cartItem, index) => (
                        <tr key={index}>
                          <td>{cartItem?.Name}</td>
                          <td>{cartItem?.count}</td>
                          <td>{cartItem?.Price}</td>
                          <td>{cartItem?.location}</td>
                          <td>{cartItem?.ID}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="modalFooter">
                    <strong>Total: ZMK {selectedCart.reduce((acc, item) => acc + item?.Price * item?.count, 0)}</strong>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminView;
