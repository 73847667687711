
import NavBar from "../../Components/NavBar/NavBar";
import Hero from "../../Components/Hero/Hero";
import Stores from "../../Components/Stores/Stores";
import Offers from "../../Components/Offers/Offers";
import Mood from "../../Components/Mood/Mood";
import NewStore from "../../Components/NewStore/NewStore";
import AreasDrop from "../../Components/AreasDrop/AreasDrop";
import FAQ from "../../Components/FAQ/FAQ";
// import Footer from "../../Components/Footer/Footer";
//import Footer from "../../Components/Foooter/Footer";
import Footer from "../../Components/Footer/Footer";
// import OurWork from "../../Components/OurWork/OurWork";
import AlsoUs from "../../Components/AlsoUs/AlsoUs";
import Listing from "../../Components/Listing/Listing";
import PartnerStores from "../../Components/PartnerStores/PartnerStores";
import Questions from "../../Components/Questions/Questions"
import Prelude from "../../Components/Prelude/Prelude";
import ProductWording from "../../Components/ProductWording/ProductWording";

function Landing({handleOrderState}){  

    const handleOrderStateSec = () =>{

       // setOrderState(false)
        handleOrderState()
        console.log("In order state function 2")
      }

    return <>
        <div className="LandingContainer"> 

           <Hero handleOrderStateSec={handleOrderStateSec} />
           <AlsoUs/>
           <Listing/>
           <ProductWording/>
           <PartnerStores/>
           {/* <Questions/> */}
           
        
           {/* <Stores/>
           <Offers/>
           <Mood/> */}
           {/* <NewStore/> */}
           {/* <AreasDrop/>
           <FAQ/> */}
           <Footer/>
           
        
     
        </div>
        
    </>

}

export default Landing;