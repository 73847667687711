

import "./PartnerStores.css"
import img1 from "../images/img1.jpg"
// import SlideCarousel from "../SlideCarousel/SlideCarousel"
import { useEffect,useState } from "react";
import useContentful from "../../Hooks/Contentful";

   import SlideCarousel from "../SlideCarouselSec/SlideCarousel";
// useContentful




function PartnerStores(){  

        const{getPartners} = useContentful()
        const [cardData, setCardData] = useState()

        useEffect(() => {
                console.log("Hello")
             // getAuthors().then((response) => console.log("ressedagt",response));
              getPartners().then((response) => response && setCardData(response));
            },[]);
        
            

    const cardData2 = [ 
        { 
           ImgUrl: img1,
           Name: "Snowflake Chartels", 
           Title: "Nike Converse",
           Price: 300
     
        },
        { 
           ImgUrl: img1,
           Name: "Zed Watch Guy1", 
           Title: "Casio Classic Watch",
           Price: 250
     
        }, 
         { 
           ImgUrl: img1,
           Name: "Snowflakes Chartel", 
           Title: "Airforce Converse",
           Price: 300
     
        }, 
         { 
           ImgUrl: img1,
           Name: "Snowflakes Chartel", 
           Title: "Airforce Converse",
           Price: 300
     
        }, 
     
     
       ]
     


    return <>
        <div className="ListingContainer"> 

            <div   className="ListingHeadingCover" id="stores">   

                    <h1>OUR PARTNERS</h1>

            </div>

            {/* <div className="ListingDisplayCover">
            {cardData && cardData.map((index,key)=>{
                return( 
                   

                    <div className="ListingItemCard" key={key}>

                            <div className="ListingImg">

                                    <img src={img1} />

                            </div>
                            <div className="ListingStore"> 

                                    <p><b>{index.Name}</b></p>

                            </div>
                            <div className="ListingDetails">

                                    <div className="ListingName">
                                        <p>{index.Title}</p>

                                    </div>
                                    <div className="ListingPrice">

                                            
                                            <p>{`ZMK ${index.Price}`}</p>

                                    </div>

                            </div>

                    </div>

           
                    
                )
            })}

</div> */}

                
                <SlideCarousel Heading={"Partners"} CarouselData={cardData} />
            

          
           
        
     
        </div>
        
    </>

}

export default PartnerStores;




