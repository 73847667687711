

import axios from 'axios';
//const BASE_URL = 'http://localhost:2002';
const BASE_URL = 'https://ntumaibii.onrender.com';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "true"
 },
    withCredentials: true
});