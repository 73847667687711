

import { Link } from "react-router-dom";
import "./ProductWording.css";
import img1 from "../images/NtumaiLanding.png";
import useContentful from "../../Hooks/Contentful";
import { useState, useEffect } from "react";

function ProductWording() {
  const { getPosters } = useContentful();
  const [cardData, setCardData] = useState();

  // useEffect(() => {
  //   getPosters().then((response) => response && setCardData(response));
  // }, [getPosters]);

  useEffect(() => {
    getPosters().then((response) => response && setCardData(response));
  }, []);

  return (
    <div className="product-wording-container">
      <div className="product-wording-cover">
        <div className="word-part">
          <h1>NTUMAI</h1>
          <p>
            Welcome to Ntumai errands. We developed Ntumai because we believe that you shouldn't have to spend a fortune to carry out errands.
          </p>
          <Link className="link-items" to="/order">
            <button>Order Now</button>
          </Link>
        </div>
        <div className="image-part">
          <img src={img1} alt="Ntumai Landing" />
        </div>
      </div>
    </div>
  );
}

export default ProductWording;




// import { Link } from "react-router-dom";
// import "./ProductWording.css"
// import img1 from "../images/NtumaiLanding.png"
//  import useContentful from "../../Hooks/Contentful";
// import { useState,useEffect } from "react";






// function ProductWording() {
    
//       const{getPosters} =  useContentful()

// //       useEffect(() => {
// //         console.log("Hello")
// //      // getAuthors().then((response) => console.log("ressedagt",response));
// // //       getPosters().then((response) => response && setCardData(response));
// //     },[]);

//     const [cardData, setCardData] = useState()

//   return (

//         <div className="ProductWordingContainer">   


//             <div className="ProductWordingCover">  

                  
//                     <div className="wordPart"> 

//                             <h1>NTUMAI</h1>
//                             <p>Welcome to Ntumai errands. We developed Ntumai because we believe that you shouldn't have to spend a fortune to carry out errands.</p>
//                             <Link className="linkItems" to={"/order"}><button>Order Now</button></Link>
                            
//                     </div>
//                       <div className="imagePart"> 

//                             <img src={img1} />

//                     </div>
            
//              </div>


            
//         </div>
//   );
// }

// export default ProductWording;
