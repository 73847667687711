// import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import {faComments } from '@fortawesome/free-solid-svg-icons'
//import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import "./Footer.css"
// function FAQ(){  


import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {faHeart,faPhone,faLocationPin,faTools,faUserShield,faBars ,faTimes,faSackDollar,faUser,faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
// import {faAirbnb} from "@fortawesome/free-brands-svg-icons";


   



function Footer(){  

    const fbnerd = "https://m.me/Nerdarr"
    const tsup = "https://wa.me/260776604969"
    const fbMillie = "https://m.me/ntumaizambia"
    const Linkedin = "https://www.linkedin.com/company/ntumai/"
    const Twitter = "https://x.com/Ntumaizambia"

    return <>
    <div className="FooterCover">
    <div className="iconRow">
                   <div className="rowPart">
                         <div className="Footericons">
                            
                         <Link className='text-link' to={'tel:+260776604969'}><FontAwesomeIcon className="fontawesomIcons" icon={faPhone}> </FontAwesomeIcon> </Link>
                         <Link className='text-link' to={fbMillie}><FontAwesomeIcon className="fontawesomIcons" icon={faFacebook}> </FontAwesomeIcon> </Link>
                         <Link className='text-link' to={tsup}><FontAwesomeIcon className="fontawesomIcons" icon={faWhatsapp} /></Link> 
                              {/* <Link className='text-link' to={fbMillie}> <FontAwesomeIcon className="fontawesomIcons" icon={faFacebook} /></Link> */}
                              <Link className='text-link' to={Twitter}><FontAwesomeIcon className="fontawesomIcons" icon={faTwitter} /></Link> 
                              
                              {/* <Link className='text-link' to={fbMillie}><FontAwesomeIcon className="fontawesomIcons" icon={faInstagram} /></Link>    */}
                              <Link className='text-link' to={Linkedin}><FontAwesomeIcon className="fontawesomIcons" icon={faLinkedin} /></Link> 
                        </div>
                   </div>
                   {/* <div className="rowPart">
                        <p>2024 © Ntumai. All Rights Reserved.</p>
                   </div> */}
                
             </div>
        
        </div>

        <div className='footerText'>
                  <p>© 2024 Ntumai All Rights Reserved. Terms of Use, Privacy Policy and Ntumai Cookies Policy</p>
        </div>
        <div className="devRow">
                     <div className="DevRowPart">
                        <p>Developed with</p>
                        <FontAwesomeIcon className="fontawesomIcons" icon={faHeart} />
                        <Link className='text-link' to={fbnerd}><p><u>@Nerdarr</u></p></Link>
                   </div>

             </div>
        <div className="needHelp">
                     <FontAwesomeIcon className="fontawesomIcons" icon={faComments} />
                    <p> Do you need Help?</p>
            </div>

        
    </>

}

export default Footer;