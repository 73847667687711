
import React, {useState} from 'react';
import "./AreasDrop.css"

import img1 from '../images/img1.jpg' 

 


function AreasDrop() { 


  const [PhylState,setPhlState] = useState(true) 
  const [consist,setConsist] = useState(false) 
  const [creative,setCreative] = useState(false) 

  const phylHandler = ()=>{ 
            
       setPhlState(!PhylState); 
       if(consist || creative){ 
        setConsist(false); 
        setCreative(false)

       }
       
      console.log("phyl",PhylState)
      console.log("consist",consist) 
      console.log("creat",creative)
  } 

  const creativeHandler = ()=>{ 
            
    setCreative(!creative); 
    if(consist || PhylState){ 
     setPhlState(false); 
     setConsist(false)

    }
    
   console.log("phyl",PhylState)
   console.log("consist",consist) 
   console.log("creat",creative)
}

const consistHandler = ()=>{ 
            
  setConsist(!consist); 
  if(PhylState || creative){ 
   setPhlState(false); 
   setCreative(false)

  }
  
 console.log("phyl",PhylState)
 console.log("consist",consist) 
 console.log("creat",creative)
}


  



  return <>
    <div className='Vforeword'>
                <h2>Ntumai in every area.</h2>
    </div>
  
  <div className='Virtue'>  

   
        <div className='Virtues'>  


        <div className={PhylState ? "PhylState Active": "PhylState"} onClick={phylHandler}>  

                    <div className='subHeading'> 
                              <div className='headTitle'> 
                                        <h3>Lusaka South</h3> 
                              </div> 

                              <div className='plusSign'> 
                                        <h3>+</h3>
                              </div>

                              <div className='minusSign'> 
                                        <h3>-</h3>
                              </div>
                                        
                    </div>
        <div className='VText'>  

        <p>Stores Here</p>

        </div>
       
        </div> 

        <div className={consist? "Consist Active": "Consist"} onClick={consistHandler}> 
        <div className='subHeading'>  
                  <div className='headTitle'> 
                            <h3>Lusaka East</h3> 
                  </div> 

                  <div className='plusSign'> 
                            <h3>+</h3>
                  </div>

                  <div className='minusSign'> 
                            <h3>-</h3>
                  </div>
                                    
                                    
        </div>
        
        <div className='VText' > 
           <p>Stores Here</p>
        </div>

        
        </div>  

          <div className={creative? "Creative Active": "Creative"} onClick={creativeHandler}>  

                <div className='subHeading'> 
                            <div className='headTitle'> 
                                      <h3>Lusaka West</h3> 
                            </div> 

                            <div className='plusSign'> 
                                    <h3>+</h3>
                            </div>

                            <div className='minusSign'> 
                                    <h3>-</h3>
                            </div>
                              
              </div>
                        
        <div className='VText' >  

           <p>Stores Here</p>
        
        </div>


          </div> 

        </div>

      

         {/* <div className='vitueImage'> 
                     <img src={img1} />

         </div> */}
      

  </div>
  </>
  
  ;
}

export default AreasDrop;