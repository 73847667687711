
import React, { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
const LeafletGeocoder = () => {
  const map = useMap();
  useEffect(() => {
    L.Control.geocoder({
        defaultMarkGeocode: false
      })
        .on('markgeocode', function(e) {
            var latlng = e.geocode.center;
            L.marker(latlng).addTo(map).bindPopup(e.geocode.name).openPopup();
            map.fitBounds(e.geocode.bbox);
        })
        .addTo(map);
        // .on('markgeocode', function(e) {
        //   var bbox = e.geocode.bbox;
        //   var poly = L.polygon([
        //     bbox.getSouthEast(),
        //     bbox.getNorthEast(),
        //     bbox.getNorthWest(),
        //     bbox.getSouthWest()
        //   ]).addTo(map);
        //   map.fitBounds(poly.getBounds());
        // })
        // .addTo(map);
  }, []);
  return null;
};

export default LeafletGeocoder;