// import React, { useEffect, useState } from "react";



import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";

const LeafletRoutingMachine = (props) => {
  const map = useMap();
  const routes = L.layerGroup().addTo(map);
  let DefaultIcon = L.icon({
    // iconUrl: "/marche.gif",
    iconUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
    // iconSize: [90, 90],
  });
  useEffect(() => {

    const markers = [];
    
    var marker1 = L.marker([-15.4175227, 28.2773157], { icon: DefaultIcon }).addTo( 
    // var marker1 = L.marker([-15.4175227, 28.2773157], { icon: DefaultIcon }).addTo( 
    // var marker1 = L.marker([36.8065, 10.1815], { icon: DefaultIcon }).addTo( 
      map
    );
    markers.push(marker1);
        map.on("click", function (e) {
  console.log("length",markers.length)
    // if (markers.length >= 2) {
    //     const oldestMarker = markers.shift();
    //     map.removeLayer(oldestMarker);
    //     }
    
    //     const newMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(map);
    //     markers.push(newMarker);


    //   L.marker([e.latlng.lat, e.latlng.lng]).addTo(map);
    //   L.Routing.control({
    //     waypoints: [
    //       L.latLng(36.8065, 10.1815),
    //       L.latLng(e.latlng.lat, e.latlng.lng),
    //     ],
    //     lineOptions: {
    //       styles: [
    //         {
    //           color: "blue",
    //           weight: 4,
    //           opacity: 0.7,
    //         },
    //       ],
    //     },
    //     routeWhileDragging: false,
    //     geocoder: L.Control.Geocoder.nominatim(),
    //     addWaypoints: false,
    //     draggableWaypoints: false,
    //     fitSelectedRoutes: true,
    //     showAlternatives: true,
    //   })
         // Update the routing control with the new waypoints
    
         if (markers.length >= 2) {
            const oldestMarker = markers.shift();
            map.removeLayer(oldestMarker);
          }
      
          const newMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(map);
          markers.push(newMarker);
      
          // Update the waypoints array to only include the last two markers
          const lastTwoMarkers = markers.slice(-2);
          const waypoints = lastTwoMarkers.map(marker =>
            L.latLng(marker.getLatLng().lat, marker.getLatLng().lng)
          );



 const routingControl = L.Routing.control({
        waypoints: waypoints,
        // lineOptions: {
        //   styles: [
        //     {
        //       color: "blue",
        //       weight: 4,
        //       opacity: 0.7,
        //     },
        //   ],
        // },
        // routeWhileDragging: false,
        // geocoder: L.Control.Geocoder.nominatim(),
        // addWaypoints: false,
        // draggableWaypoints: false,
        // fitSelectedRoutes: true,
        // showAlternatives: true,
      })
        .on("routesfound", function (e) {
            console.log("found route",e)

            const route = e.routes[0];
            // const routeTwo = e?.routes[1];
            const start = route.name;
            const startLatLng = route.waypoints[0].latLng;
            // const end = routeTwo?.name[0];
            const endLatLng = route.waypoints[1].latLng;
            const distance = route.summary.totalDistance;
            const time = route.summary.totalTime;
            // const route = e.routes[0];
            // const start = route.waypoints[0].name;
            // const startLatLng = route.waypoints[0].latLng;
            // const end = route.waypoints[1].name;
            // const endLatLng = route.waypoints[1].latLng;
            // const distance = route.summary.totalDistance;
            // const time = route.summary.totalTime;

            console.log("Starting address:", start);
  console.log("Starting coordinates:", startLatLng);
//   console.log("Ending address:", end);
  console.log("Ending coordinates:", endLatLng);
  console.log("Total distance:", distance);
  console.log("Estimated time:", time);

  props.onRouteFound({
            start,
         startLatLng,
     
         endLatLng,
        distance,
         time
  });


          e.routes[0].coordinates.forEach((c, i) => {
            setTimeout(() => {
              marker1.setLatLng([c.lat, c.lng]);
            }, 1000 * i);
          });
        })
        .addTo(map);

         // Remove the previous route layer
      routes.clearLayers();

      // Add the new route layer to the map
      routingControl.addTo(map);

      // Add the new route layer to the layer group
    //   routes.addLayer(routingControl.getPlan().getRoute());

    });
  }, []);
  return null;
};

export default LeafletRoutingMachine;


// const routesFound = (e) => {
//    const orderLocation = {

//         start,
//          startLatLng,
//         // const end = routeTwo?.name[0];
//          endLatLng,
//         distance,
//          time

//     }
//     return orderLocation
// };