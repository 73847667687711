import "./FAQ.css"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faChartLine,faTools, } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function FAQ(){  
    return <>
    <div className="FAQCover">
             <div className="writeUp">
                 <p>
                     Are you at work and want a coffee? Lunchtime at home and can't be<br/> bother to cook? It's the weekend and you don't want to get out of bed <br/> but the fridge is empty because you didn't go to the supermarket? You<br/> have company coming over and you need a liquor store RIGHT NOW?<br/> That's why Ntumai is here. Anything you want, whenever you want it<br/> - we've got it! Order selecting between 20.000 stores in 100 cities! You<br/> know this already - Ntumai is delivery in Zambia!
                 </p>
                 <div className="FAQicons">
                       
                        <FontAwesomeIcon className="fontawesomIcons" icon={faFacebook} />
                        <FontAwesomeIcon className="fontawesomIcons" icon={faTwitter} />
                        <FontAwesomeIcon className="fontawesomIcons" icon={faYoutube} />
                        <FontAwesomeIcon className="fontawesomIcons" icon={faInstagram} />
                        <FontAwesomeIcon className="fontawesomIcons" icon={faLinkedin} />
                 </div>

             </div>
             <div className="Columns">
                    <div className="column">
                        <ul>
                            <li>Who we are</li>
                            <li>How it works</li>
                            <li>FAQs</li>
                            <li>Blog</li>
                        </ul>

                    </div>
                    <div className="column">
                        <ul>
                            <li>Contact us</li>
                            <li>Do you have a store?</li>
                            <li>Become Ntumai rider</li>
                            <li>Job opportunities</li>
                        </ul>

                    </div>
                    <div className="column">
                        <ul>
                            <li>Payment methods</li>
                            <li>Terms of use</li>
                            <li>Allergens</li>
                            <li>Privacy policy  </li>
                        </ul>

                    </div>
                    <div className="column">
                        <ul>
                            <li>Rider's policy</li>
                            <li>Personal data protection policy</li>
                            <li>Cookies Policy</li>
                            <li>Stores</li>
                        </ul>

                    </div>

                    

                    



   





             </div>

     
        </div>
        
    </>

}

export default FAQ;