import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Order from '../Order/Order';
// import "https://unpkg.com/leaflet-control-geocoder/dist/Control.Geocoder.css"
import LeafletGeocoder from '../../Components/LeafletGeocoder/LeafletGeocoder';
import L from 'leaflet';
import LeafletRoutingMachine from '../../Components/LeafletRoutingMachine/LeafletRoutingMachine';
// import 'leaflet-routing-machine';
import "leaflet-control-geocoder/dist/Control.Geocoder.css"
import "leaflet-control-geocoder/dist/Control.Geocoder.js"
 import "leaflet/dist/leaflet.css"


import "./MapsDemo.css"
// import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';



// function MapsDemo( {onRouteFound }) {
function MapsDemo( props) {

  const position = [-15.4175227, 28.2773157];
// const [startCoords, setStartCoords] = useState(null);
//     const [endCoords, setEndCoords] = useState(null);
//     const [routeCoordinates, setRouteCoordinates] = useState({ start: null, end: null });

//   const handleRouteFound = (e) => {
//     console.log("i pulled this",e);
//         //  <Order handleRouteFound={handleRouteFound}/>
//     // const start = e.routes[0].summary.startPoint;
//     // const end = e.routes[0].summary.endPoint;
//     // setRouteCoordinates({ start, end });
//   };

  const [routingMachineProps, setRoutingMachineProps] = useState({});

  // const handleRouteFound = (event) => {
  //   setRoutingMachineProps({
  //     testObject: event
  //     // start: event.routes[0].summary.start,
  //     // end: event.routes[0].summary.end,
  //     // distance: event.routes[0].summary.totalDistance,
  //     // time: event.routes[0].summary.totalTime,
  //     // coordinates: event.routes[0].coordinates,
  //   });
  // };
   


// const position = [36.8065, 10.1815];

  return <div className='mapContainer'>
      <MapContainer center={position} zoom={13} scrollWheelZoom={false}> 
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
           
            {/* <Marker position={position} >
           
            <Popup>
                Our Pretty HQ. <br /> Easily accessible.
            </Popup>
            </Marker> */}
            <LeafletGeocoder/>
            {/* <LeafletRoutingMachine onRouteFound={handleRouteFound}  /> */}
            <LeafletRoutingMachine onRouteFound={props.onRouteFound}   />
        </MapContainer>

  </div>
   

  

  

  
}

// let defaultIcon = L.icon({
//     iconUrl:""
// })

const defaultIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
    // iconUrl: 'https://api.mapbox.com/icons/v3/mapbox/marker/pin-m+285E75.png',
    iconSize: [30, 30],
  });

  L.Marker.prototype.options.icon =defaultIcon
  

export default MapsDemo;


