import React, { useState } from 'react';
import './Pay.css'; // Import your custom CSS file

const MobileMoneyPayment = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [reference, setReference] = useState(''); // New field for reference
  const [operator, setOperator] = useState('mtn'); // New field for operator
  const [country, setCountry] = useState(''); // New field for country
  const [transactionId, setTransactionId] = useState('');
  const [otp, setOtp] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [paymentStage, setPaymentStage] = useState('initiate'); // 'initiate' or 'otp'

  const initiatePayment = async () => {
    try {
      //const response = await fetch('https://api.lenco.co/v2/mobile-money/initiate-collection', {
      const response = await fetch('https://lencopay.onrender.com/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer 8b6ba44842a243ea4e62e29f9fb6894adee47a0adb5c338395c41d764692e90c', // Replace with your API token
        },
        body: JSON.stringify({

          "operator": "airtel",
          "bearer": "customer",
          "amount": 1,
          "reference": "260971873484",
          "phone": "0971873484",
          "country": "zm"
          // amount: amount,
          // reference: reference,
          // phone: phoneNumber,
          // operator: operator,
          // country: country,
          // bearer: 'customer', // Hardcoded as 'merchant' per your requirement
        }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setTransactionId(data.transaction_id);
        setPaymentStage('otp');
        setStatusMessage('Payment initiated successfully. Please enter the OTP sent to your phone.');
      } else {
        console.log("catch1",data)
        setStatusMessage(`Initiation failed: ${data.message}`);
        
      }
    } catch (error) {
      // console.log("catch",data)
      setStatusMessage(`Error initiating payment: ${error.message}`);
    }
  };

  const submitOtp = async () => {
    try {
      const response = await fetch('https://api.lenco.com/v2/mobile-money/submit-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 8b6ba44842a243ea4e62e29f9fb6894adee47a0adb5c338395c41d764692e90c', // Replace with your API token
        },
        body: JSON.stringify({
          otp: otp,
          transaction_id: transactionId,
        }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setStatusMessage('Payment processed successfully!');
      } else {
        setStatusMessage(`OTP submission failed: ${data.message}`);
      }
    } catch (error) {
      setStatusMessage(`Error submitting OTP: ${error.message}`);
    }
  };

  return (
    <div className="payment-container">
      <h1>Mobile Money Payment</h1>
      {statusMessage && <div className="status-message">{statusMessage}</div>}
      
      {paymentStage === 'initiate' && (
        <div className="form-section">
          <input
            type="text"
            className="input-field"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
          />
          <input
            type="number"
            className="input-field"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
          />
          <input
            type="text"
            className="input-field"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            placeholder="Enter reference"
          />
          <input
            type="text"
            className="input-field"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Enter country"
          />
          <select
            className="input-field"
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
          >
            <option value="mtn">MTN</option>
            <option value="airtel">Airtel</option>
            {/* Add other operators as needed */}
          </select>
          <button onClick={initiatePayment} className="submit-button">Initiate Payment</button>
        </div>
      )}

      {paymentStage === 'otp' && (
        <div className="form-section">
          <input
            type="text"
            className="input-field"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
          />
          <button onClick={submitOtp} className="submit-button">Submit OTP</button>
        </div>
      )}
    </div>
  );
};

export default MobileMoneyPayment;
