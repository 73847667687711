
import { useContext } from "react"; 

import AuthContext from "../Context/AuthProvider"; 

// useContext(AuthContext); 

const useAuth = () =>{
     return useContext(AuthContext)
}

export default useAuth; 