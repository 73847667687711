


// import {
//     Box,
//     Button,
//     ButtonGroup,
//     Flex,
//     HStack,
//     IconButton,
//     Input,
//     SkeletonText,
//     Text,
//   } from '@chakra-ui/react'
import OrderSteps from "../../Components/OrderSteps/OrderSteps";
import LogoImg from "../../Components/images/ntumaiLogo.png"
import { Link } from "react-router-dom";
import useAuth from "../../Hooks/UseAuth";


  
  import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
  } from '@react-google-maps/api'
  import "./Order.css"

  import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
    import { faChartLine,faTools,faComments,FaTimes,faLocationPin, faTimes } from '@fortawesome/free-solid-svg-icons'
    import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'


  import { useRef, useState,useEffect } from 'react'
import Navbar from "../../Components/NavSec/NavSec";

  const bounds = {
    north: 39.980392,
    south: 39.254704,
    east: 3.463581,
    west: 2.263324,
}

const restrictions = {
    country: 'ZM',
}

const options = {
    strictBounds: true,
};
  
//   const center = { lat: 48.8584, lng: 2.2945 }

   // findeco
  const center = {lat: -15.4175227, lng: 28.2773157};
  
  function App({Location,cartItem,orderState}) {


    console.log("order cartItem",cartItem)
    console.log("order cartdagagadItem",Location)
    console.log("Orderstate",orderState)



    // useEffect(() => {
    //   // if (initialOriginRefValue) {
    //     // originRef.current.value = initialOriginRefValue;
    //     originRef.current.value = "Cairo";
    //   // }
    // }
    
    // , []);
    // // }, [initialOriginRefValue]);




    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyDw59gPssVHEg1TcHoC9at1KDF98yVnQe4",
    //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
    })
  
    const [map, setMap] = useState(/** @type google.maps.Map */ (null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const {auth} = useAuth()
    const [distance, setDistance] = useState('')
    const [distanceF, setDistanceF] = useState('')
    const [duration, setDuration] = useState('')
    const [splaceid, setSplaceid] = useState('')
    const [dplaceid, setDplaceid] = useState('')
    const [localeStart, setLocaleStart] = useState(cartItem?.length>0? cartItem[0]?.location : 'Pick up')
    // const [localeStart, setLocaleStart] = useState(Location?.length>0? Location : 'Pick up')
    const [durationF, setDurationF] = useState('')
    const [orderViz, setorderViz] = useState(orderState? true: false)
   
    
    ///second test
        const [startAddress, setStartAddress] = useState('');
    const [endAddress, setEndAddress] = useState('');
    const [startCoords, setStartCoords] = useState([]);
    const [endCoords, setEndCoords] = useState([]);
    // const [distance, setDistance] = useState(0);
    // const [duration, setDuration] = useState('');
  
    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()

    // useEffect(() => { 

    //        originRef.current.value = "Chilanga Post Office, Chilanga, Zambia'";

    //         // if (localeStart.length) {
    //         //   originRef.current.value = initialOriginRefValue;
             
    //         // }
  
    // }, [])

    // useEffect(() => {
    //   if (originRef.current) {
    //     originRef.current.value = "Chilanga Post Office, Chilanga, Zambia";
    //   }
    // }, []);

    // useEffect(() => {
    //   const updateOriginRef = async () => {

    //     console.log("originref",originRef)
    //     // fgd
    //     if (originRef.current) {
    //       originRef.current = "Chilanga Post Office, Chilanga, Zambia";
    //     }
    //   };
    
    //   updateOriginRef();
    // }, []);

    // useEffect(()=>{
    //       console.log("order auth",auth)
         
    // },[])
    
    
    
  
    if (!isLoaded) {
      return 
    //   <SkeletonText />
    }
  
    async function calculateRoute() {
      if (originRef.current.value === '' || destiantionRef.current.value === '') {
        return
      }

      console.log("Vale",originRef.current.value)
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const results = await directionsService.route({
        // origin: `Chilanga Post Office, Chilanga, Zambia`,
       
        origin: originRef.current.value,
        destination: destiantionRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      setDirectionsResponse(results)
      console.log("Results",results)
      setDistanceF(results.routes[0].legs[0].distance.text)
      setDistance(results.routes[0].legs[0].distance.value)
      setDuration(results.routes[0].legs[0].duration.value)
      setDurationF(results.routes[0].legs[0].duration.text)
      setStartAddress(results.request.origin.query)
      setEndAddress(results.request.destination.query)
      // setStartAddress(results.routes[0].legs[0].start_address)
      // setEndAddress(results.routes[0].legs[0].end_address)
      setSplaceid(results.geocoded_waypoints[1].place_id)
      setDplaceid(results.geocoded_waypoints[0].place_id)

      setorderViz(true)

    }
  
    function clearRoute() {
      setDirectionsResponse(null)
      setDistance('')
      setDuration('')
      setorderViz(false)
      originRef.current.value = ''
      destiantionRef.current.value = ''
    }

  
    return (
        <>

         <div className='gmapCover'>

          
            <div className="loggedHeader">
              <Navbar/>
               {/* <div className="Logo" onClick={()=>{console.log("I have been clicked")}}>
               <Link className="textLink" to="/"><img src={LogoImg} /></Link>
                  
               </div>
               <div className="SignIn">
                  <h1>{String(auth?.foundUser?.name).substring(0,1)}</h1>
                 
               </div> */}
               
            </div>

             <div className='gmapControls'>
                <div className='gcontrolCover'>
                    <div className='itemsCover'>
                    <div className='itemCover2'>

                        <div className='controlFirspart'>
                                    <div className='topControls'>

                                    <div className="topControlInput">
                                        <Autocomplete

                                            restrictions={restrictions}
                                            options={options}
                                        
                                        >
                                        {/* <input type='text' placeholder='Pick Up' ref={originRef} /> */}
                                        <input type='text' placeholder='Pick Up'
                                        disabled={cartItem.length > 0}
                                        value={localeStart}
                                          onChange={(e)=>{
                                               console.log(e.target.value)
                                                setLocaleStart(e.target.value)

                                          }}
                                         ref={originRef}
                                          />
                                        </Autocomplete>
                                    </div>
                                    <div className="topControlInput" >
                                        <Autocomplete

                                          restrictions={restrictions}
                                            options={options}
                                        

                                        >
                                        {/* <input
                                            type='text'
                                            placeholder='Drop Off'
                                            ref={destiantionRef}
                                        /> */}
                                        <input
                                            type='text'
                                            placeholder='Drop Off'
                                            ref={destiantionRef}
                                        />
                                        </Autocomplete>
                                    </div>

                                 
                            </div>

                                {/* End of top Control */}

                                <div className='bottomControls'>

                                        <p>Distance: {distanceF} </p>
                                        <p>Duration: {durationF} </p>
                                       
                                    

                                </div>

                                {/* End of bottom contols */}

                        </div>

                        <div className='controlSecpart'>

                                         <button type='submit' onClick={calculateRoute}>
                                             Make Order
                                        </button>
                                         {/* <button type='submit' onClick={calculateRoute}>
                                             Calculate Route
                                        </button>
                                         <button type='submit' onClick={(e)=>{
                                            setorderViz(true)}}>
                                             Make Order
                                        </button> */}

                                   

                        </div>

                        <div className='controlThirdpart'>
                            
                                    <div> <FontAwesomeIcon icon={faTimes} className="FontAwesomeIcon"

                                        onClick={clearRoute}
                                     
                                    /></div>

                                  <div> <FontAwesomeIcon icon={faLocationPin} className="FontAwesomeIcon"

                                        onClick={() => {
                                            map.panTo(center)
                                            map.setZoom(15)
                                            }}
                                        
                                        /></div>

                        </div>

                  


                     </div>
                    </div>

                </div>
                
                {/* Control Bottom end */}
                <div className='controlBottom'>
                    {orderViz && 
                       <OrderSteps

                            // start={start}
                            // end={end}
                            // distance={distance}
                            // duration={duration}
                            startAddress={startAddress}
                            endAddress={endAddress}
                            startCoords={startCoords}
                            endCoords={endCoords}
                            distance={distance}
                            duration={duration}
                            setDistance = {setDistance}
                            setDuration = {setDuration}
                            setStartAddress = {setStartAddress}
                            setEndAddress = {setEndAddress}
                            cartItem = {cartItem}
                            orderState={orderState}
                            splaceid ={splaceid}
                            dplaceid = {dplaceid}

                            />

                    }
                
                

                </div>

                  

             </div>
             <div className='gmap'>

                <GoogleMap
                    center={center}
                    zoom={15}
                    mapContainerStyle={{ width: '100vw', height: '100vh' }}
                    options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    }}
                    onLoad={map => setMap(map)}
                >
                    <Marker position={center} />
                    {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                    )}
                </GoogleMap>

             </div>

         </div>

          
       
      

      
        </>
    )
  }
  
  export default App


// import NavBar from "../../Components/NavBar/NavBar";
// import Hero from "../../Components/Hero/Hero";
// import Stores from "../../Components/Stores/Stores";
// import Offers from "../../Components/Offers/Offers";
// import Mood from "../../Components/Mood/Mood";
// import NewStore from "../../Components/NewStore/NewStore";
// import AreasDrop from "../../Components/AreasDrop/AreasDrop";
// import FAQ from "../../Components/FAQ/FAQ";
// import Footer from "../../Components/Footer/Footer";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import OrderSteps from "../../Components/OrderSteps/OrderSteps";
// // useState
// import MapsDemo from "../MapsDemo/MapsDem";
// import "./Order.css"
// import { useState } from "react";

// function Order(){  
//     console.log("iin order steps")

//     // const [start, setStart] = useState(null);
//     // const [end, setEnd] = useState(null);
//     // const [distance, setDistance] = useState(null);
//     // const [duration, setDuration] = useState(null);
  
//     // function handleRouteFound(route) {
//     //     console.log("Order Order",route)
//     // //   setStart(route.waypoints[0].latLng);
//     // //   setEnd(route.waypoints[1].latLng);
//     // //   setDistance(route.summary.totalDistance);
//     // //   setDuration(route.summary.totalTime);
//     // }

//     const [startAddress, setStartAddress] = useState('');
//     const [endAddress, setEndAddress] = useState('');
//     const [startCoords, setStartCoords] = useState([]);
//     const [endCoords, setEndCoords] = useState([]);
//     const [distance, setDistance] = useState(0);
//     const [duration, setDuration] = useState('');
  
//     // const handleRouteFound = (start, end, route) => {
//     const handleRouteFound = (e) => {
//         console.log("in second handle",e)
//       setStartAddress(e?.start);
//       setEndAddress(e?.address);
//       setStartCoords([e?.startLatLng.lat,e?.startLatLng.lng]);
//       setEndCoords([e?.endLatLng.lat,e?.endLatLng.lng]);
//       setDistance(e?.distance);
//       setDuration(e?.time);
//       console.log("xxxxxxxxx",e?.distance)
//     //   setStartAddress(start.address);
//     //   setEndAddress(end.address);
//     //   setStartCoords([start.latLng.lat, start.latLng.lng]);
//     //   setEndCoords([end.latLng.lat, end.latLng.lng]);
//     //   setDistance(route.routes[0].summary.totalDistance);
//     //   setDuration(route.routes[0].summary.totalTime);
//     };
   

//     return <>
//         <div className="LandingContainer"> 

//            <SearchBar/>
//            <div className="mapCover">
//                 {/* <MapsDemo onRouteFound={handleRouteFound} /> */}
//                 <MapsDemo onRouteFound={handleRouteFound} />
//            </div>
//            <OrderSteps

//                     // start={start}
//                     // end={end}
//                     // distance={distance}
//                     // duration={duration}
//                     startAddress={startAddress}
//                     endAddress={endAddress}
//                     startCoords={startCoords}
//                     endCoords={endCoords}
//                     distance={distance}
//                     duration={duration}
           
//            />
          
           
           
        
     
//         </div>
        
//     </>

// }

// export default Order;