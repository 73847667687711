


import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin, faShuttleVan, faPeopleCarry, faHamburger, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import {faChartLine,faClock,faStar,faTools,faUserShield ,faTimes,faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

import "./AlsoUs.css"




function AlsoUs(){  



        const scrollToSection = (id) => {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
                // toggleMenu();
              };
            
            
              
            
        //         return (
        //             <div className="NavWhole">
        //                 <div className='NavWholeContainer'>
        //                     <div className='Logo'>
                                
        //                         {/* <Link to={"/"}  className='linkItems'><h2>Anthu</h2></Link> */}
        //                         <Link to={"/"}  className='linkItems'><img src={img1} /></Link>
        //                     </div>
            
        //                     <div className={`Goto ${isOpen ? 'open' : ''}`}>
        //                         <ul>
        //                             <li> <Link to={"/"}  className='linkItems'><span onClick={() => scrollToSection('about')}>About</span></Link></li>
        
        // function AlsoUs(){  













    return <>
    <div className="AlsoUsCover">


            <div className="AlsoUsCoverforeword">

                        <h1>Our Services</h1>
                        
            </div>

            <div className="AlsoUsChartcardsCover">
            <div className="AlsoUsChartcards">

                        <div className="AlsoUsChart">
                                <Link to={"/order"} className='linkItems'>

                                <div className="AlsoUsIcon">

                                   <FontAwesomeIcon className='AlsoUsIcons' icon={faShuttleVan} />

                                </div>
                                <div className="Wording">
                                        <p>COURIER</p>
                                </div>

                                </Link>

                        </div>
                        <div className="AlsoUsChart">

                        <Link to={"/order"} className='linkItems'>

                                <div className="AlsoUsIcon">

                                      <FontAwesomeIcon className='AlsoUsIcons' icon={faPeopleCarry} />

                                </div>
                                <div className="Wording">
                                        <p>ERRANDS</p>
                                </div>
                        
                        </Link>

                        </div>
                        <div className="AlsoUsChart">

                       
                        {/* <a className='linkItems' onClick={() => scrollToSection('about')} href="#iggySec"> */}
                        <a className='linkItems' onClick={() => scrollToSection('stores')} >

                                <div className="AlsoUsIcon">

                                          <FontAwesomeIcon className='AlsoUsIcons' icon={faHamburger} />

                                </div>
                                <div className="Wording">
                                        {/* <p>RESTAURANTS</p> */}
                                        <p>STORES</p>
                                </div>
                                </a>
                        
                     

                        </div>
                        <div className="AlsoUsChart">

                      
                        <a className='linkItems' onClick={() => scrollToSection('shopping')}>

                                <div className="AlsoUsIcon">

                                          <FontAwesomeIcon className='AlsoUsIcons' icon={faShoppingCart} />

                                </div>
                                <div className="Wording">
                                        <p>SHOPPING</p>
                                </div>
                                </a>
                        

                        </div>
                        
            </div>
            </div>

                       

     
        </div>
        
    </>

}

export default AlsoUs;