
import "./Listing.css"
import img1 from "../images/img1.jpg"
import { faFontAwesome, faMarkdown } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin, faShuttleVan, faPeopleCarry, faHamburger, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import {faChartLine,faClock,faStar,faTools,faUserShield ,faTimes,faBars, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { Link } from "react-router-dom"
import SlideCarousel from "../SlideCarousel/SlideCarousel";


import useContentful from "../../Hooks/Contentful";
import { useEffect,useState } from "react";

function Listing(){  

    const { getAuthors } = useContentful();

    useEffect(() => {
        console.log("Hello")
     // getAuthors().then((response) => console.log("ressedagt",response));
      getAuthors().then((response) => response && setCardData(response));
    },[]);

    const [cardData, setCardData] = useState()

    const cardDatavvv = [ 
        { 
           ImgUrl: img1,
           Name: "Snowflake Chartels", 
           Title: "Nike Converse",
           Price: 300,
           location : "Chilanga Post Office, Chilanga, Zambia",
           ID  : 1
  
     
        },
        { 
           ImgUrl: img1,
           Name: "Zed Watch Guy", 
           Title: "Casio Classic Watch",
           Price: 250,
           location : "Findeco House, Lusaka, Zambia",
           ID  : 2
     
        }, 
         { 
           ImgUrl: img1,
           Name: "Snowflakes Chartel", 
           Title: "Airforce Converse",
           Price: 300,
           location : "Down town mall, Lusaka, Zambia",
           ID  : 3
     
        }, 
         { 
           ImgUrl: img1,
           Name: "Snowflakes Chartel", 
           Title: "Airforce Converse",
           Price: 300,
           location : "Chilanga Post Office, Chilanga, Zambia",
           ID  : 4
     
        }, 
     
     
       ]
  


    return <>
        <div  className="ListingContainer" id="shopping"> 

            <div className="ListingHeadingCover" >   

                    <h1>TOP LISTINGS</h1>

            </div>

            {/* <div className="ListingDisplayCover">
            {cardData && cardData.map((index,key)=>{
                return( 
                   
                    <Link className="textLink" to={`/productOrder/${index.ID}`}> 
                   
               

                    <div className="ListingItemCard" key={key}>




                            <div className="ListingImg">

                                    <img src={index.imgUrl.fields.file.url} />

                            </div>
                            <div className="ListingStore"> 

                                    <p><b>{index.name}</b></p>

                            </div>
                            <div className="ListingDetails">

                                    <div className="ListingName">
                                        <p>{index.name}</p>

                                    </div>
                                    <div className="ListingPrice">

                                              <p>{`ZMK ${index.price}`}</p>

                                    </div>

                            </div>
                            <div className="Radar">
                                <p>< FontAwesomeIcon className="radarFontIcon" icon={faMapMarker} />{index?.location}</p>
                            </div>

                    </div>

                    </Link>
                    
                )
            })}

</div> */}



        <SlideCarousel 
                // Heading={"Related items"}

                CarouselData = {cardData}
            
            />
            

          
           
        
     
        </div>
        
    </>

}

export default Listing;

// import React, { useState } from 'react';
// import "./Listing.css";
// import img1 from "../images/img1.jpg";

// function Listing() {
//     const [currentIndex, setCurrentIndex] = useState(0);

//     const cardData = [
//         { ImgUrl: img1, Name: "Snowflake Chartels", Title: "Nike Converse", Price: 300 },
//         { ImgUrl: img1, Name: "Zed Watch Guy", Title: "Casio Classic Watch", Price: 250 },
//         { ImgUrl: img1, Name: "Snowflakes Chartel", Title: "Airforce Converse", Price: 300 },
//     ];

//     const handleSlide = (index) => {
//         setCurrentIndex(index);
//     };

//     return (
//         <>
//             <div className="ListingContainer">
//                 <div
//                     className="ListingDisplayCover"
//                     style={{ transform: `translateX(-${currentIndex * 100}%)` }}
//                 >
//                     {cardData.map((item, index) => (
//                         <div
//                             className="ListingItemCard"
//                             key={index}
//                             onClick={() => handleSlide(index)}
//                         >
//                             <div className="ListingImg">
//                                 <img src={item.ImgUrl} alt={`Card ${index + 1}`} />
//                             </div>
//                             <div className="ListingStore">
//                                 <p><b>{item.Name}</b></p>
//                             </div>
//                             <div className="ListingDetails">
//                                 <div className="ListingName">
//                                     <p>{item.Title}</p>
//                                 </div>
//                                 <div className="ListingPrice">
//                                     <p>{item.Price}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Listing;



