import { useState } from "react";
import {useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useAuth from "../../Hooks/UseAuth";
import "./Login.css"
// import ClipLoader from "react-spinners/ClipLoader";
import axios from '../../api/axios';
import BeatLoader from "react-spinners/BeatLoader";
import LogoImg from "../../Components/images/ntumaiLogo.png"

// import { Link,useLocation,useNavigate } from "react-router-dom";

function Login(){  
    
    let navigate = useNavigate();
    let location = useLocation();
	const [email, setEmail] = useState('');
    const [name, setName] = useState('')
	const [password, setPassword] = useState(''); 
    const {setAuth, auth} = useAuth()
    const [signUp, setSignup] = useState(false); 
    const [loginState, setLogin] = useState(true); 
    const [forgotPwdState, setForgotPwdStaten] = useState(false); 
    const [sucessRecovery, setSucessRecovery] = useState(false); 
    const [register, setRegister] = useState(false);
    const [Branch, setBranch] = useState("");
    const [OrgName, setOrgName] = useState("");
    const [Address, setAddress] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [AccNo, setAccNo] = useState("");
    const [Loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [Msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);
    const LOGIN_URL = '/auth';
    const BASE_URLLOCAL ="http://localhost:2000/"
    const BASE_URLONLINE ="https://superagentback.onrender.com"

    //
   
	// const [email, setEmail] = useState('')
	// const [password, setPassword] = useState('') 

    
    
    async function LoginUser(e) {  
        e.preventDefault();  
        setLoading(true)
        setSuccessMsg(false);
        setErrorMsg(false);
       
        const from = location?.state?.from?.pathname || "/";
        // const from = location?.state?.from?.pathname || "/UserDash";

         const response = await fetch("http://localhost:2002/auth",{ 
        //  const response = await fetch("https://superagentback.onrender.com/api/login",{ 
                method : "POST", 
                headers: { 
                 "content-type" :   "application/json"
                }, 
                body: JSON.stringify({
                    email,
                    password
                })
                

         }) 
 
         const data = await response.json();
        // console.log(data,"data")
        //  if(data==undefined){
            
        //  }
         if(data.status== "ok"){ 
            setLoading(false)
           // console.log("Data",data.data);
            // var authData = data.data;
            // localStorage.setItem('tokem',data.user);
            // setAuth({user: email, roles : [data.data.role,"test role"], dat : data.data});
            setAuth((prev) => ({
                ...prev,
                user: email, roles : [data.data.role,"test role"], dat : data.data
              }));
      
           // console.log("Email" ,email, "Role",data.data.role);
           // console.log("AUth",auth.user)
           // alert("login successful!");
            // navigate("/dashboard");
            navigate(from,{replace: true});
         } else{ 
            // alert("Unauthorized access") 
            setLoading(false);
            setMsg(data.msg)
            setErrorMsg(true);

         }
         
    }

    

    const handleAuthForm = (e) =>{
          e.preventDefault()
          setLogin(!loginState); 
          setRegister(!register);
          
    }
    const handleFOrgotPwd = () =>{
          setForgotPwdStaten(true)
          setLogin(false); 
          setRegister(false);
          
    }

  
    async function registerUser(e) {  
        e.preventDefault(); 
        setLoading(true);
        setSuccessMsg(false);
        setErrorMsg(false);

        //  const response = await fetch("https://superagentback.onrender.com/api/register",{ 
        //  const response = await fetch("http://localhost:2001/api/authregister",{ 
        //  const response = await fetch(BASE_URLONLINE+"/Orgs",{ 
             
        //         method : "POST",
        //         headers: { 
        //          "content-type" :   "application/json",
        //          'Access-Control-Allow-Credentials': true
        //         }, 

        //         credentials: 'include',
        //         // ,
        //         body: JSON.stringify({
        //             name,
        //             email,
        //             password,
        //             AccNo,
        //             Branch,
        //             OrgName
        //         }),
                

            
                

        //  }) 
        const response = await axios.post("/users",
            JSON.stringify({ 
                // email, password,

                              name,
                    email,
                    password,
                    AccNo,
                    Branch,
                    Address ,
                    PhoneNumber,

                    // name : req.body.name,
                    // email : req.body.email,
                    // // password : req.body.password,
                    //  password : hashedPwd,
                    // AccountNo : req.body.AccNo,
                    
            
            
            }),
            // JSON.stringify({ user, pwd }),
            {
                headers: { 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Credentials': 'true' ,
                // 'Origin': 'https://www.google.com/'
                // 'Origin': 'http://localhost:3000'
             },
                
                withCredentials: true,
                crossDomain: true,

            }
        );

        //  const data = await response.json();
         const data = await response;
        // console.log(data);

            // if(data==undefined){
                
            // }

          if(data.data.status == 'Ok'){
            // navigate("/login");
            setLoading(false);
            setSuccessMsg(true)
            setMsg(data?.data.msg)
            setRegister(false)
            setLogin(true)
            
            // handleAuthForm()
          }else{
            // alert("Operation Failed")
            setLoading(false);
            setMsg(data?.data.msg)
            setErrorMsg(true);
          }
    }
   
    const handleSubmit = async (e) => {
        // e.preventDefault();
        e.preventDefault();  
        setLoading(true)
        setSuccessMsg(false);
        setErrorMsg(false);
       
        // const from = location?.state?.from?.pathname || "/UserDash";
        const from = location?.state?.from?.pathname || "/";

        try {
           // console.log("email",email,"and",password)
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, password }),
                // JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Credentials': 'true' ,
                    // 'Origin': 'https://www.google.com/'
                    // 'Origin': 'http://localhost:3000'
                 },
                    
                    withCredentials: true,
                    crossDomain: true,
  
                }
            );
           // if(response?.data?.accessToken !=""){
            if(response?.data?.status =="Ok"){

                // console.log("whole",JSON.stringify(response?.data));
              //  console.log(JSON.stringify(response));
                const user = response?.data?.email;
                const accessToken = response?.data?.accessToken;
                const roles = response?.data?.roles;
                const foundUser = response?.data?.foundUser;
                setAuth({});
                setAuth({ user, roles : [roles,"test role"], accessToken, foundUser});
                // setAuth({ user, pwd, roles, accessToken });
                //console.log("autheeeeee",auth)
                setLoading(false)
           
            // alert("login successful!");
            // navigate("/dashboard");
            navigate(from,{replace: true});

            }else{  

                setLoading(false);
                setMsg(response?.data?.msg)
                setErrorMsg(true);
                
            }
            
            // navigate(from, { replace: true });
        } catch (err) {
           
        }
    }

    ///Handle forgot submit
    const fgtPwdHandler = async (e) => {
        // e.preventDefault();
        e.preventDefault();  
        setLoading(true)
        setSuccessMsg(false);
        setErrorMsg(false);
       
        const from = location?.state?.from?.pathname || "/UserDash";

        try {
           // console.log("email",email,"and",password)
            const response = await axios.post(LOGIN_URL+"/UsrRecovery",
                JSON.stringify({ email, password }),
                // JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Credentials': 'true' ,
                    // 'Origin': 'https://www.google.com/'
                    // 'Origin': 'http://localhost:3000',
                    'x-client-host': window.location.hostname
                 },
                    
                    withCredentials: true,
                    crossDomain: true,
          
                }
            );
            if(response?.data?.accessToken !=""){

                setForgotPwdStaten(false)
                setSucessRecovery(true)

                // console.log("whole",JSON.stringify(response?.data));
                //console.log(JSON.stringify(response));
                const user = response?.data?.email;
                const accessToken = response?.data?.accessToken;
                const roles = response?.data?.roles;
                const foundUser = response?.data?.foundUser;
                setAuth({});
                setAuth({ user, roles : [roles,"test role"], accessToken, foundUser});
                // setAuth({ user, pwd, roles, accessToken });
                //console.log("autheeeeee",auth)
                setLoading(false)
           
            // alert("login successful!");
            // navigate("/dashboard");
            // navigate(from,{replace: true});

            }
            
            // navigate(from, { replace: true });
        } catch (err) {
           
        }
    }


    


    return( 

        <>  
            <div className="center">
                {loginState &&<>

                    {/* <h1>Login</h1> */}
                    <div className="Logo">
                            <Link className="textLink" to="/"> 
                            <img src={LogoImg} />
                        </Link>   
                       
                    </div>
                        <form onSubmit={handleSubmit}>
                            <div className="txt_field">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                // placeholder="Email"
                                required
                            />
                            <span></span>
                            <label>Email</label>

                            </div>
                            <div  className="txt_field">

                                    <input
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            // placeholder="Password"
                                            required
                                        />

                                    <span></span>
                                    <label>Password</label>

                            </div>

                            
                                
                           {/* Loading Animation */}
                            {Loading ?
                            <div>
                                <BeatLoader color="#36d7b7" />
                              
                            </div> :  
                                <>
                                     <input type="submit" value="Login" />
                                     {errorMsg && <div className="errorMsgContainer">
                                            
                                            {/* <p>{`Operation Failed! ${Msg}`}</p> */}
                                            <p>{`${Msg}`}</p>
                                            
                                 </div>}
                                 {successMsg && <div className="successMsgContainer">
                                            
                                            {/* <p>{`Operation successfull! ${Msg}`}</p> */}
                                            <p>{`${Msg} Login`}</p>
                                            
                                </div>}
                                        <div className="signup_link">
                                        Not a member? <a href="#" onClick={e =>{handleAuthForm(e)}}>Signup</a>
                                    </div>

                                    <div className="signup_link">
                                          <a href="#" onClick={handleFOrgotPwd}>Forgot your password?</a>
                                    </div>

                                </>   
                             
                             } 

                           
                           
                        </form>

                </>}
                {forgotPwdState &&<>

                    {/* <h1>Account Recovery</h1> */}
                    
                    <div className="Logo">
                            <Link className="textLink" to="/"> 
                            <img src={LogoImg} />
                        </Link>  
                    </div>
                        <form onSubmit={fgtPwdHandler}>
                            <div className="txt_field">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                // placeholder="Email"
                                required
                            />
                            <span></span>
                            <label>Email</label>

                            </div>
                                
                           {/* Loading Animation */}
                            {Loading ?
                            <div>
                                <BeatLoader color="#36d7b7" />
                              
                            </div> :  
                                <>
                                     <input type="submit" value="Recover Account" />
                                     {errorMsg && <div className="errorMsgContainer">
                                            
                                            <p>{`${Msg}`}</p>
                                            
                                 </div>}
                                        <div className="signup_link">
                                        Not Registered? <a href="#" onClick={handleAuthForm}>Signup</a>
                                    </div>

                                </>   
                             
                             } 

                           
                           
                        </form>

                </>}
                {sucessRecovery &&<>

                    <div className="Logo">
                            <Link className="textLink" to="/"> 
                            <img src={LogoImg} />
                        </Link>  
                    </div>

                    {/* <h1>Account Recovery</h1> */}
                        {/* <form onSubmit={fgtPwdHandler}> */}
                        <div className="recoveryacount">    
                        <p> We have sent you mail to reset your Ntumai password.</p> 

                        {/* <p>Click the Reset Password in that mail.</p>  */}

                        <p>If you didn't receive the reset password mail, please check the promotion/spam folder.</p> 
                        
     

                        </div>

                    
                           
                        {/* </form> */}

                </>}
                 {register &&<>

                    {/* <h1>Register</h1> */}
                    
                    <div className="Logo">
                            <Link className="textLink" to="/"> 
                            <img src={LogoImg} />
                        </Link>  
                    </div>
                        <form onSubmit={registerUser}>
                            {/* Name */}
                            <div className="txt_field">
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                required
                            />
                            <span></span>
                            <label>Your Name</label>

                            </div>

                            {/* Email */}
                            <div className="txt_field">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                required
                            />
                            <span></span>
                            <label>Email</label>

                            </div>
                            {/* Email */}
                            <div className="txt_field">
                            <input
                                value={Address}
                                onChange={(e) => setAddress(e.target.value)}
                   
                                type="text"
                                required
                            />
                            <span></span>
                            <label>Address</label>

                            </div>
                            {/* AccountNumber */}
                            <div className="txt_field">
                            <input
                                value={PhoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                type="number"
                                required
                            />
                            <span></span>
                            <label>Phone Number</label>

                            </div>

                            {/* Branch */}
                          
                            
                            <div  className="txt_field">

                                    <input
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            // placeholder="Password"
                                            required
                                        />

                                    <span></span>
                                    <label>Password</label>

                            </div>

                            
                            {Loading ?<div>
                               
                                <BeatLoader color="#36d7b7" />
                            </div> :    
                            <>
                            <input type="submit" value="Sign Up" />
                           
                                
                                {successMsg && <div className="successMsgContainer">
                                            
                                            {/* <p>{`Operation successfull! ${Msg}`}</p> */}
                                            <p>{`${Msg}`}</p>
                                            
                                </div>}
                                {errorMsg && <div className="errorMsgContainer">
                                            
                                            <p>{`${Msg}`}</p>
                                            
                                </div>}

                                <div className="signup_link">
                                Already a member? <a href="#" onClick={handleAuthForm}>Sign in </a>
                                </div>
                                <div className="signup_link">
                                          <a href="#" onClick={handleFOrgotPwd}>Forgot your password?</a>
                                    </div>
                            
                            </>}
                                
                            
                            
                        </form>

                </>}
			
		</div>
      


        </>
    )

}

export default Login;