
// Navbar.js
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../Hooks/UseAuth";
import useLogout from "../../Hooks/useLogout";
import LogoImg from "../../Components/images/ntumaiLogo.png";
import { useState } from "react";
import "./NavSec.css"; // You can create a separate CSS file for navbar styles
//import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin, faShuttleVan, faPeopleCarry, faHamburger, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

const Navbar = ({ handleOrderStateSec }) => {
  const { auth } = useAuth();
  const logout = useLogout();
  const [visible, setVisible] = useState(false);

  const handleSignOut = async () => {
    await logout();
    console.log("logged out");
  };

  const toggleVisibility = () => setVisible(!visible);

  return (
    <div className="navbar">
      {/* <div className="logo">
        <img src={LogoImg} alt="Logo" />
      </div> */}
      <Link to="/" className="logo"> {/* Wrap the image in a Link */}
        <img src={LogoImg} alt="Logo" />
      </Link>
      <div className="nav-items">
          <Link to={"/cart"} className='linkItems'>

              <div className="AlsoUsIcon3">

                <FontAwesomeIcon className='AlsoUsIcons3' icon={faShoppingCart} />

              </div>
        </Link>
        {auth.foundUser ? (
          <div className="logged-btn">
            <button onClick={toggleVisibility}>
              {auth?.foundUser?.name.charAt(0)}
            </button>
            <div className={`overlay-dropdown ${visible ? 'show' : ''}`}>
              <div className="dropdown-content">
                <div className="email-section">
                  <p>{auth?.user}</p>
                  <FontAwesomeIcon icon={faTimes} onClick={() => setVisible(false)} />
                </div>
                <div className="icon-section">
                  <Link className="linkitemheroSec" to="/Order" onClick={toggleVisibility}>
                    ORDERS
                  </Link>
                </div>
                <div className="icon-section">
                  <Link className="linkitemheroSec" to="/Cart" onClick={toggleVisibility}>
                    CART
                  </Link>
                </div>
                {auth?.Role === 'Admin' &&
                <div className="icon-section">
                  <Link className="linkitemheroSec" to="/Dashboard" onClick={toggleVisibility}>
                    DASHBOARD
                  </Link>
                </div> }
                <div className="name-section">
                  <p>{`Hi, ${auth?.foundUser?.name}!`}</p>
                </div>
                <div className="actions-section" onClick={handleSignOut}>
                  <p><FontAwesomeIcon icon={faSignOutAlt} /> Sign Out</p>
                </div>
                <div className="policies-section">
                  <p>Privacy policy . Terms of service</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <button className="login-btn">
            <Link className="linkitemhero" to="/login">Login/Sign up</Link>
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;


// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import useAuth from "../../Hooks/UseAuth";
// import LogoImg from "../../Components/images/ntumaiLogo.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
// import './NavSec.css';

// function NavSec() {
//   const { auth } = useAuth();
//   const [visible, setVisible] = useState(false);

//   const toggleVisibility = () => {
//     console.log("yeah")
//     setVisible(prevVisible => !prevVisible); // Ensure the state is properly toggled
//   };

//   const SignOut = async () => {
//     console.log("logged out");
//     // Your logout logic here
//   };

//   return (
//     <>
//       <div className="NavBar">
//         <div className="Logo">
//           <Link className="textLink" to="/">
//             <img src={LogoImg} alt="Logo" />
//           </Link>
//         </div>

//         <div className="NavItems">
//           {auth?.foundUser ? (
//             <div className="loggedBtn">
//               {/* Display the first letter of the user's name */}
//               <button onClick={toggleVisibility}>
//                 {auth?.foundUser?.name.charAt(0)}
//               </button>

//               {/* Conditional rendering for the dropdown */}
//               {visible && (
//                 <div className="overlayDropdown">
//                   <div className="DOverlayCover">
//                     <div className="emailSection">
//                       <div className="email">
//                         <p>{auth?.user}</p>
//                       </div>
//                       <div className="cross">
//                         <FontAwesomeIcon
//                           onClick={toggleVisibility}
//                           icon={faTimes}
//                           className="FontAwesomeIcon"
//                         />
//                       </div>
//                     </div>

//                     {/* Navigation Links */}
//                     <div className="iconSection">
//                       <Link className="textLink" to="/Order" onClick={toggleVisibility}>
//                         <h2>ORDERS</h2>
//                       </Link>
//                     </div>
//                     <div className="iconSection">
//                       <Link className="textLink" to="/Cart" onClick={toggleVisibility}>
//                         <h2>CART</h2>
//                       </Link>
//                     </div>
//                     <div className="iconSection">
//                       <Link className="textLink" to="/Dashboard" onClick={toggleVisibility}>
//                         <h2>DASHBOARD</h2>
//                       </Link>
//                     </div>

//                     <div className="nameSection">
//                       <p>{`Hi, ${auth?.foundUser?.name}!`}</p>
//                     </div>

//                     {/* Sign Out Option */}
//                     <div className="actionsSection" onClick={SignOut}>
//                       <p>
//                         <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
//                       </p>
//                     </div>

//                     <div className="policiesSection">
//                       <p>Privacy policy . Terms of service</p>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           ) : (
//             <button className="softbtn">
//               <Link className="textLink" to="/login">
//                 Login/Sign up
//               </Link>
//             </button>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default NavSec;


// import React from 'react'
// import { Link } from "react-router-dom";
// // import LogoImg from "../../Components/images/ntumaiLogo.png"
// import useAuth from "../../Hooks/UseAuth";
// import LogoImg from "../../Components/images/ntumaiLogo.png"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import AuthContext from "../Context/AuthProvider";
// import { faPlus,faSignOutAlt,faCogs,faSuitcaseRolling,faTools,faUser,faUsers,faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
// import { useContext, useState } from "react";
// // import {usestate} from 'react'
// // import { useState } from "react";
// import '../../Components/Hero/Hero.css'



// function NavSec(){

//     const {auth} = useAuth()
//     const {visible,setVisible} = useState(true)

//     const SignOut = async () => {
//         // await logout();
//         // navigate('/')

//         console.log("logged out")
//     }


//   const setSignViz = () =>{
//     setVisible(!visible)

// }



//     return(
//         <>      

//         <div className="NavBar">  
//         <div className="Logo">
//                  {/* <h2>Ntumai</h2> */}
//                  <Link className="textLink" to="/"> 
//                      <img src={LogoImg} />
//                  </Link>
                
//         </div>

//         <div className="NavItems">  

//         <div className="NavItems">
//                  {auth.foundUser?
//                    <div className="loggedBtn"> 
//                      <button onClick={setSignViz} >
//                             {String(auth?.foundUser?.name).substring(0,1)}
//                      </button>  

//                      { visible  &&

//                         //   for logout logic

//                             <div className="overlayDropdown" >

//                                 <div className="DOverlayCover"> 
//                                     <div className="emailSection"> 

//                                             <div className="email"> <p>{String(auth?.user)}</p> </div>
//                                             <div className="cross"><p><FontAwesomeIcon onClick={()=>{setSignViz(false)}} icon={faTimes} className="FontAwesomeIcon"/> </p> </div>
                                            

//                                     </div>
//                                     <div className="iconSection"> 
//                                         <button onClick={
//                                            ()=>{
//                                             setSignViz
//                                             handleOrderStateSec()
//                                             console.log("order handler")
//                                            }
                                            
//                                             }>
//                                         <Link className="textLink" to="/Order"><h2>{"ORDERS"}</h2></Link>

                                                
//                                                 {/* <h2>{ String(auth?.user).substring(0,1).toUpperCase()}</h2> */}
                                            
//                                         </button>

//                                     </div>
//                                     <div className="nameSection"> 
//                                                 <p>{`Hi, `+ String(auth?.foundUser?.name)+`!`}</p>

//                                     </div>
//                                     <div className="actionsSection"> 

//                                             {/* <div className="child"><p><FontAwesomeIcon icon={faPlus} className="FontAwesomeIcon"/> {"Add Account"+`    |    `} </p> </div> */}
//                                             <div className="child" onClick={SignOut}><p><FontAwesomeIcon icon={faSignOutAlt} className="FontAwesomeIcon"/> {``+"Sign Out"}</p>  </div>

//                                     </div>
//                                     <div className="policiesSection"> 

//                                                 <p>Privacy policy . Terms of service</p>

//                                     </div>


//                                 </div>

                                

//                             </div>
//                             }
                                                
                      
//                      </div>

                        
                     
//                       :

//                      <button className="softbtn" onClick={()=> {
                        
//                      console.log("btn click")}}> 
//                           {/* <Link className="textLink" to="Order">Login/Sign up</Link> */}
                        
//                              <Link className="textLink" to="/login">Login/Sign up</Link>
               
                    
//                      </button>

//                  }
                   
//                </div>
       
//         </div>  

//         {/* Signout function */}

          

//         { visible  &&           <div className="overlayDropdown" >

//                                             <div className="DOverlayCover"> 
//                                                   <div className="emailSection"> 

//                                                         <div className="email"> <p>{String(auth?.foundUser?.name)}</p> </div>
//                                                         <div className="cross"><p><FontAwesomeIcon onClick={()=>{setSignViz(false)}} icon={faTimes} className="FontAwesomeIcon"/> </p> </div>
//                                                         {/* <div className="icon">X</div> */}

//                                                   </div>
//                                                   <div className="iconSection"> 
//                                                       <button onClick={setSignViz}>

//                                                                <h2>{ String(auth?.foundUser?.name).substring(0,1).toUpperCase()}</h2>
                                                        
//                                                      </button>

//                                                   </div>
//                                                   <div className="nameSection"> 
//                                                               <p>{`Hi, `+ String(auth?.foundUser?.name)+`!`}</p>

//                                                   </div>
//                                                   <div className="actionsSection"> 

//                                                         <div className="child"><p><FontAwesomeIcon icon={faPlus} className="FontAwesomeIcon"/> {"Add Account"+`    |    `} </p> </div>
//                                                         {/* <div className="child" onClick={SignOut}><p><FontAwesomeIcon icon={faSignOutAlt} className="FontAwesomeIcon"/> {``+"Sign Out"}</p>  </div> */}

//                                                   </div>
//                                                   <div className="policiesSection"> 

//                                                             <p>Privacy policy . Terms of service</p>

//                                                   </div>


//                                             </div>

//                                             {/* <button onClick={SignOut}>SIGN OUT HERE</button> */}

//                                         </div>
//                             }
                                  

//     </div>
        
//         </>
//     )

// }



// export default NavSec;