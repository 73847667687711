
import React, { useState } from 'react';
import './AdminDB.css';
import NavBar from '../../Components/NavBar/NavBar';
import { Link, Outlet, useLocation } from 'react-router-dom';

function MoreInfo() {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='AdminDB'>
      <NavBar />
      <div className='AdminNav'>
        <div className={`left ${isCollapsed ? 'collapsed' : ''}`}>
          <button onClick={toggleSidebar} className='toggleButton'>
            {isCollapsed ? 'Expand' : 'Collapse'}
          </button>
          {!isCollapsed && (
            <>
              <h2>ADMIN</h2>
              <ul>
                <li>
                  <Link
                    className={`listItem ${location.pathname === '/admin/Dashboard' ? 'active' : ''}`}
                    to='/admin/applicants'
                  >
                    Dashboard
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`listItem ${location.pathname === '/admin/applicants' ? 'active' : ''}`}
                    to='/admin/applicants'
                  >
                    Applicants
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`listItem ${location.pathname === '/admin/orders' ? 'active' : ''}`}
                    to='/admin/orders'
                  >
                    Orders
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`listItem ${location.pathname === '/admin/courses' ? 'active' : ''}`}
                    to='/admin/courses'
                  >
                    Courses
                  </Link>
                </li>
                <li>
                  <Link
                    className={`listItem ${location.pathname === '/admin/Intakes' ? 'active' : ''}`}
                    to='/admin/Intakes'
                  >
                    Intakes
                  </Link>
                </li> */}
              </ul>
            </>
          )}
        </div>
        <div className='right'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MoreInfo;




// import React, {useState} from 'react';

// import "./AdminDB.css"
// import NavBar from '../../Components/NavBar/NavBar';
// import ContactUsForm from '../../Components/ContactUsForm/ContactUsForm';
// import Faqs from '../../Components/FAQs/FAQs';
// import Footer from '../../Components/Footer/Footer';
// import { Link, Outlet } from 'react-router-dom';
// // Link



// function MoreInfo() { 
 

//   return <>

//       <div className='AdminDB'>   

//             <NavBar/>

//             <div className='AdminNav'>  

//                         <div className='left'>
//                                 <h2>ADMIN</h2>
//                                 <ul>    
//                                   <li><Link className="listItem" to={"/admin/applicants"}>Applicants</Link></li>
//                                   <li><Link className="listItem" to={"/admin/students"}>Students</Link></li>
//                                   <li><Link className="listItem" to={"/admin/courses"}>Courses</Link></li>
                               

//                                 </ul>

//                         </div>
//                         <div className='Right'>

//                                 <Outlet/>
                      
                      
//                         </div>

//             </div>

            


//       </div>
       
//   </>
  
//   ;
// }

// export default MoreInfo;