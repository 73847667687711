import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { axiosPrivate } from '../../api/axios';
import './Students.css';

const Student = () => {
  const [students, setStudents] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    async function getCourses() {
      try {
        const response = await axiosPrivate.get('/users');
        if (response) {
          console.log('students', response);
          setStudents(response.data);
        } else {
          console.log('pull fail');
        }
      } catch (e) {
        console.log('catch error', e);
      }
    }
    getCourses();
  }, []);

  const fetchAttachments = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASEURL}/images`, {
        params: { UserID: userId }
      });
      if (response) {
        console.log('pulled', response);
        setSelectedFiles(response.data);
        setIsModalOpen(true);
      } else {
        console.log('pull fail');
      }
    } catch (e) {
      console.log('catch error', e);
    }
  };

  const handleStatusChange = async (index, student, newStatus) => {
    const confirmed = window.confirm(`Do you want to update the status to ${newStatus}?`);
    if (confirmed) {
      setLoading(true);
      const updatedStudents = [...students];
      const studentz = updatedStudents[index];
      studentz.ReviewStatus = newStatus;
      try {
        await axios.post(process.env.REACT_APP_BASEURL + '/api/application/update', {
          User_ID: student.User_ID,
          IndexID: student.IndexID,
          ReviewStatus: student.ReviewStatus
        });
        setStudents(updatedStudents);
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return date.toLocaleDateString("en-US", {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const sortedStudents = students.sort((a, b) => parseInt(b.CreatedDate) - parseInt(a.CreatedDate));

  const filteredStudents = sortedStudents.filter((student) => {
    const statusMatch = statusFilter ? student.Status === statusFilter : true;
    const dateMatch =
      (!dateRange.start || new Date(student.date) >= new Date(dateRange.start)) &&
      (!dateRange.end || new Date(student.date) <= new Date(dateRange.end));
    return statusMatch && dateMatch;
  });

  return (
    <div className="studentContainer">
      <div className="header">
        <h2>User List</h2>
        <div className="filters">
          <select value={statusFilter} onChange={handleFilterChange} className="filterDropdown">
            <option value="">All Statuses</option>
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>
          <input
            type="date"
            name="start"
            value={dateRange.start}
            onChange={handleDateChange}
            className="filterDate"
          />
          <input
            type="date"
            name="end"
            value={dateRange.end}
            onChange={handleDateChange}
            className="filterDate"
          />
        </div>
      </div>
      {loading && <p>Loading...</p>}
      <table className="studentTable">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Last Serviced</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{student.name}</td>
              <td>{student.email}</td>
              <td>{student.PhoneNumber}</td>
              <td>{student.Address}</td>
              <td>{formatTimestamp(student.CreatedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Student;
