
import { useLocation,Navigate,Outlet } from "react-router"; 


import useAuth from "../../Hooks/UseAuth"; 
// import { useLocation } from "react-router";

const RequireAuth = ({allowedRoles}) =>{ 

     const {auth} = useAuth(); 
     const location = useLocation(); 
     //console.log("auth",auth)
     return( 
        auth?.roles?.find(role => allowedRoles?.includes(role))
        ? <Outlet />
        : auth?.users
            ? <Navigate to="/unauth" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
     );

}

export default RequireAuth; 

// auth?.roles.find(role => allowedRoles?.includes(role)) 
// ? <Outlet />
//  : auth?.user
//  ?<Navigate to="/unauth" state ={{from : location}} replace />
//  :<Navigate to =  "/unauth" state ={{from : location}} replace />


///
// auth?.user 
// ? <Outlet /> : <Navigate to =  "/" state ={{from : location}} replace />