import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import './Applicants.css';
import { axiosPrivate } from '../../api/axios'; // Make sure axiosPrivate is properly configured

const Dash = () => {
  const [Orders, setOrders] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [carrierData, setCarrierData] = useState([]);
  const [metrics, setMetrics] = useState({
    totalOrders: 0,
    totalInProgress: 0,
    totalCompleted: 0,
    totalPending: 0,
    totalDistance: 0,
    avgDuration: 0,
    totalRevenue: 0,
  });

  // Date range filters
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  // Helper function to parse float safely
  const safeParseFloat = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  const getChartMetrics = (data) => {
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Apply filters for date range and status
    const filteredData = data
      .filter((item) => {
        const itemDate = new Date(item.OrderDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        return (!start || itemDate >= start) && (!end || itemDate <= end);
      })
      .filter((item) => (statusFilter ? item.Status === statusFilter : true));

    // Group data by OrderDate and Status
    const groupedData = filteredData?.reduce((acc, item) => {
      const date = formatDate(item.OrderDate);
      const status = item.Status;

      if (!acc[date]) {
        acc[date] = { date, 'Driver Assigned': 0, Pending: 0, Completed: 0 };
      }
      acc[date][status] += 1;

      return acc;
    }, {});

    const chartData = Object.values(groupedData);

    // Calculate metrics
    const totalOrders = filteredData.length;
    const totalInProgress = filteredData.filter((item) => item.Status === 'Driver Assigned').length;
    const totalCompleted = filteredData.filter((item) => item.Status === 'Completed').length;
    const totalPending = filteredData.filter((item) => item.Status === 'Pending').length;
    const avgDuration = filteredData.reduce((sum, item) => sum + parseInt(item.duration), 0) / totalOrders || 0;
    const totalRevenue = filteredData.reduce((acc, item) => acc + safeParseFloat(item.Price), 0);
    const totalDistance = filteredData.reduce((acc, item) => acc + safeParseFloat(item.distance), 0);
    const totalDuration = filteredData.reduce((acc, item) => acc + safeParseFloat(item.duration), 0);

    setOrders(chartData);
    setMetrics({
      totalOrders,
      totalInProgress,
      totalCompleted,
      totalPending,
      totalDistance: Math.floor(totalDistance),
      avgDuration: Math.floor(avgDuration),
      totalRevenue: Math.floor(totalRevenue),
    });

    const statusCounts = filteredData.reduce((acc, item) => {
      acc[item.Status] = (acc[item.Status] || 0) + 1;
      return acc;
    }, {});

    const statusArray = Object.keys(statusCounts).map((key) => ({
      name: key,
      value: statusCounts[key],
    }));

    setStatusData(statusArray);

    const carrierCounts = filteredData.reduce((acc, item) => {
      acc[item.carrier] = (acc[item.carrier] || 0) + 1;
      return acc;
    }, {});

    const carrierArray = Object.keys(carrierCounts).map((key) => ({
      name: key,
      count: carrierCounts[key],
    }));

    setCarrierData(carrierArray);
  };

  useEffect(() => {
    async function getOrders() {
      try {
        const response = await axiosPrivate.get('/Orders');
        if (response) {
          console.log(response.data.propdata); // Make sure this logs the correct data
          getChartMetrics(response.data.propdata);
        } else {
          console.log('Failed to pull data from /Orders');
        }
      } catch (e) {
        console.error('Error fetching orders:', e);
      }
    }

    getOrders();
  }, [startDate, endDate, statusFilter]);

  return (
    <div className="dashContainer">
      <div className="filtersContainer">
        <label>Start Date:</label>
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />

        <label>End Date:</label>
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />

        <label>Status:</label>
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="Driver Assigned">Driver Assigned</option>
          <option value="Completed">Completed</option>
        </select>
      </div>

      {/* Scorecards */}
      <div className="ScorecardsContainer">
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Total Orders</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalOrders}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>In Progress</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalInProgress}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Completed</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalCompleted}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Pending</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalPending}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Total Distance (m)</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalDistance}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Average Duration (mins)</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.avgDuration}</h1>
          </div>
        </div>
        <div className="scorecard">
          <div className="scorecardheader">
            <h4>Total Revenue (ZMK)</h4>
          </div>
          <div className="scorecardvalue">
            <h1>{metrics.totalRevenue}</h1>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="chartWrapper">
        <h4>Orders by Status</h4>
        <ResponsiveContainer className="overflower" width="100%" height={300}>
          <LineChart data={Orders}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Pending" stroke="#8884d8" />
            <Line type="monotone" dataKey="Driver Assigned" stroke="#82ca9d" />
            <Line type="monotone" dataKey="Completed" stroke="green" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="ChartsContainer">
        <div className="chartWrapper">
          <h4>Status Distribution</h4>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={statusData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
                {statusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.name === 'Completed' ? '#82ca9d' : '#8884d8'} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chartWrapper">
          <h4>Orders by Carrier</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={carrierData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Dash;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
// import './Applicants.css';
// import { axiosPrivate } from '../../api/axios';

// const Dash = () => {
//   const [Orders, setOrders] = useState([]);
//   const [statusData, setStatusData] = useState([]);
//   const [carrierData, setCarrierData] = useState([]);
//   const [metrics, setMetrics] = useState({
//     totalOrders: 0,
//     totalInProgress: 0,
//     totalCompleted: 0,
//     totalPending: 0,
//     totalDistance: 0,
//     avgDuration: 0,
//     totalRevenue: 0,
//   });

//   const safeParseFloat = (value) => {
//     const parsed = parseFloat(value);
//     return isNaN(parsed) ? 0 : parsed;
//   };

//   const getChartMetrics = (data) => {
//     console.log("in data", data);

//     const formatDate = (dateString) => {
//       const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
//       return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     // Group data by OrderDate and Status
//     const groupedData = data?.reduce((acc, item) => {
//       const date = formatDate(item.OrderDate);
//       const status = item.Status;

//       if (!acc[date]) {
//         acc[date] = { date, 'Driver Assigned': 0, Pending: 0, Completed: 0 };
//       }
//       acc[date][status] += 1;

//       return acc;
//     }, {});

//     const chartData = Object.values(groupedData);
    
//     // Calculate metrics
//     const totalOrders = data.length;
//     const totalInProgress = data.filter(item => item.Status === 'Driver Assigned').length;
//     const totalCompleted = data.filter(item => item.Status === 'Completed').length;
//     const totalPending = data.filter(item => item.Status === 'Pending').length;
//    // const totalDistance = data.reduce((sum, item) => sum + parseFloat(item.distance), 0);
//     const avgDuration = data.reduce((sum, item) => sum + parseInt(item.duration), 0) / totalOrders || 0;
//    // const totalRevenue = data.reduce((sum, item) => sum + parseFloat(item?.Price), 0);
//     const totalRevenue = data.reduce((acc, item) =>acc + safeParseFloat(item.Price), 0);
//     const totalDistance = data.reduce((acc, item) => acc + safeParseFloat(item.distance), 0);
//     const totalDuration = data.reduce((acc, item) => acc + safeParseFloat(item.duration), 0);

//     setOrders(chartData);
//     setMetrics({
//       totalOrders,
//       totalInProgress,
//       totalCompleted,
//       totalPending,
//       totalDistance,
//       avgDuration,
//       totalRevenue
//     });

//     const statusCounts = data.reduce((acc, item) => {
//       acc[item.Status] = (acc[item.Status] || 0) + 1;
//       return acc;
//     }, {});

//     const statusArray = Object.keys(statusCounts).map((key) => ({
//       name: key,
//       value: statusCounts[key],
//     }));

//     setStatusData(statusArray);

//     const carrierCounts = data.reduce((acc, item) => {
//       acc[item.carrier] = (acc[item.carrier] || 0) + 1;
//       return acc;
//     }, {});

//     const carrierArray = Object.keys(carrierCounts).map((key) => ({
//       name: key,
//       count: carrierCounts[key],
//     }));

//     setCarrierData(carrierArray);
//   };

//   useEffect(() => {
//     async function getOrders() {
//       try {
//         const response = await axiosPrivate.get('/Orders');
//         if (response) {
//           getChartMetrics(response.data.propdata);
//         } else {
//           console.log('pull fail');
//         }
//       } catch (e) {
//         console.log('catch error', e);
//       }
//     }
//     getOrders();
//   }, []);

//   return (
//     <div className="dashContainer">
//       <div className='ScorecardsContainer'>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Total Orders</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalOrders}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>In Progress</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalInProgress}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Completed</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalCompleted}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Pending</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalPending}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Total Distance (m)</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalDistance}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Average Duration (mins)</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.avgDuration}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Total Revenue (ZMK)</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalRevenue}</h1>
//           </div>
//         </div>
//       </div>

//       <div className="chartWrapper">
//         <h4>Orders by Status</h4>
//         <ResponsiveContainer className="overflower" width="100%" height={300}>
//           <LineChart data={Orders}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="date" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             <Line type="monotone" dataKey="Pending" stroke="#8884d8" />
//             <Line type="monotone" dataKey="Driver Assigned" stroke="#82ca9d" />
//             <Line type="monotone" dataKey="Completed" stroke="green" />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>

//       <div className="ChartsContainer">
//         <div className="chartWrapper">
//           <h4>Status Distribution</h4>
//           <ResponsiveContainer width="100%" height={300}>
//             <PieChart>
//               <Pie data={statusData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
//                 {statusData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={["#8884d8", "#82ca9d", "green", "red"][index % 4]} />
//                 ))}
//               </Pie>
//               <Tooltip />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>

//         <div className="chartWrapper">
//           <h4>Orders by Carrier</h4>
//           <ResponsiveContainer width="100%" height={300}>
//             <BarChart data={carrierData}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="count" fill="#82ca9d" />
//             </BarChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dash;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
// import './Applicants.css';
// import { axiosPrivate } from '../../api/axios';

// const Dash = () => {
//   const [Students, setStudents] = useState([]);
//   const [statusData, setStatusData] = useState([]);
//   const [courseData, setCourseData] = useState([]);
//      const [metrics, setMetrics] = useState({
//      totalCourses: 0,
//      totalRegistered: 0,
//      totalStudents: 0,
//      totalApplicants: 0,
//      totalAccepted: 0,
//      totalPending: 0,
//      totalRejected: 0,
//    });

//   const getChartMetrics = (data) => {

//     console.log("in data",data)
//     const formatDate = (dateString) => {
//       const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
//       return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     const groupedData = data?.reduce((acc, item) => {
//       const date = formatDate(item.CreationDate);
//       const status = item.ReviewStatus;
//       const course = item.CourseName;

//       if (!acc[date]) {
//         acc[date] = { date, Pending: 0, Rejected: 0, Registered: 0, Accepted: 0 };
//       }
//       acc[date][status] += 1;

//       return acc;
//     }, {});

//     const chartData = Object.values(groupedData);
//    // setStudents(chartData);
//    //   Calculate metrics
//     const totalApplicants = data.length;
//     const totalAccepted = data.filter(item => item.ReviewStatus === 'Accepted').length;
//     const totalPending = data.filter(item => item.ReviewStatus === 'Pending').length;
//     const totalRejected = data.filter(item => item.ReviewStatus === 'Rejected').length;
//     const totalRegistered = data.filter(item => item.Status === 'Registered').length;

//     setStudents(chartData);
//     setMetrics({
//       totalCourses: 12, // Replace with actual calculation if available
//       totalStudents: 12, // Replace with actual calculation if available
//       totalApplicants,
//       totalAccepted,
//       totalPending,
//       totalRejected,
//       totalRegistered
//     });


//     const statusCounts = data.reduce((acc, item) => {
//       acc[item.ReviewStatus] = (acc[item.ReviewStatus] || 0) + 1;
//       return acc;
//     }, {});

//     const statusArray = Object.keys(statusCounts).map((key) => ({
//       name: key,
//       value: statusCounts[key],
//     }));

//     setStatusData(statusArray);

//     const courseCounts = data.reduce((acc, item) => {
//       acc[item.CourseName] = (acc[item.CourseName] || 0) + 1;
//       return acc;
//     }, {});

//     const courseArray = Object.keys(courseCounts).map((key) => ({
//       name: key,
//       count: courseCounts[key],
//     }));

//     setCourseData(courseArray);
//   };

//   useEffect(() => {
//     async function getCourses() {
//       try {
//         const response = await axiosPrivate.get('/Orders', {
//           // signal: controller.signal,
//           // params: {
//           //   email: auth.user,
//           //   AccountNo: auth.foundUser.AccountNo,
//           //   org: 'value2',
//           //   mgtLvl: 'value2',
//           // }
//       });
//        // const response = await axios.get(process.env.REACT_APP_BASEURL + '/api/application/all');
//         if (response) {
//           console.log('dad',response.data)
//           getChartMetrics(response.data.propdata);
         
//         } else {
//           console.log('pull fail');
//         }
//       } catch (e) {
//         console.log('catch error', e);
//       }
//     }
//     getCourses();
//   }, []);

// //   useEffect(() => {

      
  
// //     const getUsers = async () => {
// //         try {
// //             const response = await axiosPrivate.get('/Orders', {
// //                 // signal: controller.signal,
// //                 // params: {
// //                 //   email: auth.user,
// //                 //   AccountNo: auth.foundUser.AccountNo,
// //                 //   org: 'value2',
// //                 //   mgtLvl: 'value2',
// //                 // }
// //             });
            
// //             // console.log("init tdata")

// //             if(response?.data){
// //                 console.log("In admin",response)
// //                 setTasks(response.data.propdata);
// //                 // console.log("tasks",Tasks)
// //                 // if(response.data?.propdata){
// //                 //   console.log(response.data);
// //                 //   console.log("Here");
// //                 //   setTData(response?.data?.propdata);
// //                 //   console.log("tdata",tData)

// //             }
            
// //           //   isMounted && setUsers(response.data);
// //         } catch (err) {
// //             console.error(err);
// //           //   navigate('/login', { state: { from: location }, replace: true });
// //         }
// //     }
// //     // console.log("contect",auth)
// //     getUsers();
// //     console.log("tasks",Tasks)

  
// // }, [])

//   return (
//     <div className="dashContainer">
//              <div className='ScorecardsContainer'>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Courses</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalCourses}</h1>
//            </div>
//          </div>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Registered</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalRegistered}</h1>
//            </div>
//          </div>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Applicants</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalApplicants}</h1>
//            </div>
//          </div>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Accepted</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalAccepted}</h1>
//            </div>
//          </div>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Pending</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalPending}</h1>
//            </div>
//          </div>
//          <div className='scorecard'>
//            <div className='scorecardheader'>
//              <h4>Rejected</h4>
//            </div>
//            <div className='scorecardvalue'>
//              <h1>{metrics.totalRejected}</h1>
//            </div>
//          </div>
//        </div>

//       <div className="chartWrapper">
//           <h4>Applicants VS Registration Graph</h4>
//           <ResponsiveContainer className="overflower" width="100%" height={300}>
//             <LineChart data={Students}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="date" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Pending" stroke="#8884d8" />
//               <Line type="monotone" dataKey="Accepted" stroke="#82ca9d" />
//               <Line type="monotone" dataKey="Rejected" stroke="red" />
//               <Line type="monotone" dataKey="Registered" stroke="green" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>

//       <div className="ChartsContainer">
       

//         <div className="chartWrapper">
//           <h4>Status Distribution</h4>
//           <ResponsiveContainer width="100%" height={300}>
//             <PieChart>
//               <Pie data={statusData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
//                 {statusData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={["#8884d8", "#82ca9d", "red", "green"][index % 4]} />
//                 ))}
//               </Pie>
//               <Tooltip />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>

//         <div className="chartWrapper">
//           <h4>Courses and Applicants</h4>
//           <ResponsiveContainer width="100%" height={300}>
//             <BarChart data={courseData}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="count" fill="#82ca9d" />
//             </BarChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dash;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import './Applicants.css';

// const Dash = () => {
//   const [Students, setStudents] = useState([]);
//   const [metrics, setMetrics] = useState({
//     totalCourses: 0,
//     totalStudents: 0,
//     totalApplicants: 0,
//     totalAccepted: 0,
//     totalPending: 0,
//     totalRejected: 0,
//   });

//   const getChartMetrics = (data) => {
//     const formatDate = (dateString) => {
//       const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
//       return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     const groupedData = data.reduce((acc, item) => {
//       const date = formatDate(item.CreationDate);
//       const status = item.ReviewStatus;

//       if (!acc[date]) {
//         acc[date] = { date, Pending: 0, Rejected: 0, Registered: 0, Accepted: 0 };
//       }

//       acc[date][status] += 1;

//       return acc;
//     }, {});

//     const chartData = Object.values(groupedData);

//     // Calculate metrics
//     const totalApplicants = data.length;
//     const totalAccepted = data.filter(item => item.ReviewStatus === 'Accepted').length;
//     const totalPending = data.filter(item => item.ReviewStatus === 'Pending').length;
//     const totalRejected = data.filter(item => item.ReviewStatus === 'Rejected').length;

//     setStudents(chartData);
//     setMetrics({
//       totalCourses: 12, // Replace with actual calculation if available
//       totalStudents: 12, // Replace with actual calculation if available
//       totalApplicants,
//       totalAccepted,
//       totalPending,
//       totalRejected,
//     });

//     console.log("chart data", chartData);
//   };

//   useEffect(() => {
//     async function getCourses() {
//       try {
//         const response = await axios.get(process.env.REACT_APP_BASEURL + '/api/application/all');
//         if (response) {
//           console.log('DASH students', response);
//           getChartMetrics(response.data);
//         } else {
//           console.log('pull fail');
//         }
//       } catch (e) {
//         console.log('catch error', e);
//       }
//     }
//     getCourses();
//   }, []);

//   return (
//     <div className='dashContainer'>
//       <div className='ScorecardsContainer'>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Courses</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalCourses}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Students</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalStudents}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Applicants</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalApplicants}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Accepted</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalAccepted}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Pending</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalPending}</h1>
//           </div>
//         </div>
//         <div className='scorecard'>
//           <div className='scorecardheader'>
//             <h4>Rejected</h4>
//           </div>
//           <div className='scorecardvalue'>
//             <h1>{metrics.totalRejected}</h1>
//           </div>
//         </div>
//       </div>
//       <div className='SecondChartContainer'>
//         <div className='firstname'>
//           <div className='tagheading'>
//             <h4>Applicants VS Status Graph</h4>
//           </div>
//           <div className='chartOne'>
//             <ResponsiveContainer width="100%" height="100%">
//               <LineChart
//                 width={500}
//                 height={300}
//                 data={Students}
//                 margin={{
//                   top: 5,
//                   right: 30,
//                   left: 20,
//                   bottom: 5,
//                 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="date" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Line type="monotone" dataKey="Pending" stroke="#8884d8" activeDot={{ r: 8 }} />
//                 <Line type="monotone" dataKey="Accepted" stroke="#82ca9d" />
//                 <Line type="monotone" dataKey="Rejected" stroke="red" />
//                 <Line type="monotone" dataKey="Registered" stroke="green" />
//               </LineChart>
//             </ResponsiveContainer>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dash;

// import React, { useState,useEffect } from 'react';
// import axios from 'axios';

// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import './Applicants.css';

// const Dash = () => {

//   const [Students,setStudents] = useState()
//   const [chartData,setchartData] = useState()

//   const getChartMetrics  = (data) =>{
//   // Helper function to format the date
//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
//     return new Date(dateString).toLocaleDateString(undefined, options);
//   };
  
//   // Grouping logic
//   const groupedData = data.reduce((acc, item) => {
//     const date = formatDate(item.CreationDate);
//     const status = item.ReviewStatus;
  
//     if (!acc[date]) {
//         acc[date] = { date, Pending: 0, Rejected: 0, Registered : 0 , Accepted : 0};
//     }
  
//     acc[date][status] += 1;
  
//     return acc;
//   }, {});


//   // Convert groupedData object to an array
//     const chartData = Object.values(groupedData);
//     setStudents(chartData);

//     console.log("chart data",chartData);
        

//   }



//   useEffect(() => {
//     async function getCourses() {
//       try {
//         const response = await axios.get(process.env.REACT_APP_BASEURL + '/api/application/all');
//         if (response) {
//           console.log('DASH students',response);
          
//           getChartMetrics(response.data)
//         } else {
//           console.log('pull fail');
//         }
//       } catch (e) {
//         console.log('catch error', e);
//       }
//     }
//     getCourses();
//   }, []);





//   const data = [
//     {
//       name: 'Page A',
//       uv: 4000,
//       pv: 2400,
//       amt: 2400,
//     },
//     {
//       name: 'Page B',
//       uv: 3000,
//       pv: 1398,
//       amt: 2210,
//     },
//     {
//       name: 'Page C',
//       uv: 2000,
//       pv: 9800,
//       amt: 2290,
//     },
//     {
//       name: 'Page D',
//       uv: 2780,
//       pv: 3908,
//       amt: 2000,
//     },
//     {
//       name: 'Page E',
//       uv: 1890,
//       pv: 4800,
//       amt: 2181,
//     },
//     {
//       name: 'Page F',
//       uv: 2390,
//       pv: 3800,
//       amt: 2500,
//     },
//     {
//       name: 'Page G',
//       uv: 3490,
//       pv: 4300,
//       amt: 2100,
//     },
//   ];

//   return (
//     <div className='dashContainer'>

//             <div className='ScorecardsContainer'> 
              
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Courses</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Students</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Applicants</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Accepted</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Pending</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>
//                           <div className='scorecard'> 
//                                 <div className='scorecardheader'>
//                                         <h4>Rejected</h4>
//                                 </div>
//                                 <div className='scorecardvalue'>
//                                       <h1>12</h1>
//                                 </div>

//                           </div>

//             </div>
//             <div className='SecondChartContainer'>  
//                   <div className='firstname'> 
//                           <div className='tagheading'>
//                                 <h4>Applicants VS Registration Graph</h4>
//                           </div>
//                           <div className='chartOne'>  
//                           <ResponsiveContainer width="100%" height="100%">
//                           <LineChart
//                             width={500}
//                             height={300}
//                             data={Students}
//                             margin={{
//                               top: 5,
//                               right: 30,
//                               left: 20,
//                               bottom: 5,
//                             }}
//                           >
//                             <CartesianGrid strokeDasharray="3 3" />
//                             <XAxis dataKey="date" />
//                             <YAxis />
//                             <Tooltip />
//                             <Legend />
//                             <Line type="monotone" dataKey="Pending" stroke="#8884d8" activeDot={{ r: 8 }} />
//                             <Line type="monotone" dataKey="Accepted" stroke="#82ca9d" />
//                             <Line type="monotone" dataKey="Rejected" stroke="red" />
//                             <Line type="monotone" dataKey="Registered" stroke="green" />
//                           </LineChart>
//                         </ResponsiveContainer>

//                           </div>

//                   </div>
//                   {/* <div className='lastname'>  
//                           <div className='tagheading'>  

//                                    <h4>Applicants VS Registration Graph</h4>

//                             </div>

//                   </div> */}

//             </div>
  
//     </div>
//   );
// };

//export default Dash;
