
import "./OrderSteps.css"
import img1 from "../images/img1.jpg"
import img2 from "../images/img1.jpg"
import img3 from "../images/img1.jpg"
import { useEffect, useState } from "react"
import { axiosPrivate } from "../../api/axios"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,faClipboard,faChartLine,faTools,faUser,faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import {Link,useLocation, useNavigate } from "react-router";
import useAuth from "../../Hooks/UseAuth"
import MapsDemo from "../../Pages/MapsDemo/MapsDem"
// import BeatLoader from "react-spinners/BeatLoader"
import BeatLoader from "react-spinners/PropagateLoader"
import axios from "axios"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
// import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import {faHeart,faPhone,faLocationPin,faUserShield,faBars ,faTimes,faSackDollar,faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {faHeart,faPhone,faLocationPin,faUserShield,faBars ,faTimes,faSackDollar,faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

// axiosPrivate


function OrderSteps({ 
    // start, end, distance, time, coordinates
    
    startAddress,
    endAddress,
    startCoords,
    endCoords,
    distance,
    duration,
    setDistance,
    setDuration,
    setStartAddress,
    setEndAddress,
    cartItem,
    orderState,
    splaceid,
    dplaceid,



}){  
    // const { start, end, distance, time, coordinates } = props.routingMachineProps;
    // const {  end, distance, time, coordinates } = props.routingMachineProps;

    const handleRouteFound = (e) => {
        console.log("i pulled this",e)
        // const start = e.routes[0].summary.startPoint;
        // const end = e.routes[0].summary.endPoint;
        // setRouteCoordinates({ start, end });
        

        //Check if its an errand or pick & drop off
        //if errand -> check if Distance*Rate+ErrandFee > 50? Distance*Rate+ErrandFee : 50
        //else if Pick & Drop -> check if Distance*Rate > 30 Distance*Rate : 30
      };



    console.log("in ORdsers")

    const [SAddress,setSAddress] = useState();
    const [dropState,setDropState] =useState(false); 
    const [dropStateSec,setDropStateSec] =useState(false); 
    const [dropStateThird,setDropStateThird] =useState(false); 
    const [EAddress,setEAddress] = useState();
    //const [carrier,setCarrier] = useState("Select Mode of Transport");
    const [carrier,setCarrier] = useState("Bike");

    const [errand,setErrand] = useState();
    const [Details,setDetails] = useState();
    const [morders, setmorders] = useState(orderState? false : true);
    const [Tasks, setTasks] = useState([{}]);
    const [orders, setorders] = useState(orderState? true : false);
    const [Collector, setCollector] = useState();
    const [cartPrices, setCartPrices] = useState();
    const [selected,setSelected] = useState("Picking parcel yourself?"); 
    //const [errandType,setErrandType] = useState("Select Task Type"); 
    const [errandType,setErrandType] = useState("Pick & Drop Off"); 
    const options = ["Yes","No"];
    const optionsSec = ["Bike","Car"];
    const optionsThird = ["Pick & Drop Off","Errand"];
    const [Loading, setLoading] = useState(false);
    const [tidType,setType] = useState(); 
    const [priceRate,setPriceRate] = useState(6); 
    const [ErrandFee,setErrandFee] = useState(20); 
    const [calculatedPrice,setcalculatedPrice] = useState(30); 
    const auth = useAuth();

    var cartSum = 0;
    // const Tasks = [{}]

    // const  handleSubmit = () =>{

    // }


    const postMessage = async () => {
        const url = 'https://graph.facebook.com/v20.0/398815159973534/messages';
        //const token = 'EAAOSVb1krcABO43WAptE95wZArSEGyt3hsa5dFwBf4DTORdLKlyQwuWEuppHXGpMUqHA2QX9V6PomgpQYrjAibrUsXDC6mNf5vnntI96zKQe7JOCQGuaEwgdAibGDR5J2m56ltZBq5UoZCZBCYIz7zg1w2ie5U4H6S9eUc1x2Fk8qA5aa6ZCxe62zToi39rJuAtYJnpCO01216SgDWWAZD';
        const token = 'EAAOSVb1krcABO43WAptE95wZArSEGyt3hsa5dFwBf4DTORdLKlyQwuWEuppHXGpMUqHA2QX9V6PomgpQYrjAibrUsXDC6mNf5vnntI96zKQe7JOCQGuaEwgdAibGDR5J2m56ltZBq5UoZCZBCYIz7zg1w2ie5U4H6S9eUc1x2Fk8qA5aa6ZCxe62zToi39rJuAtYJnpCO01216SgDWWAZD';
        
        console.log("called")
        const data = {
          messaging_product: 'whatsapp',
          to: '260776604969',
          type: 'template',
          template: {
            name: 'hello_world',
            language: {
              code: 'en_US'
            }
          }
        };
    
        try {
          const response = await axios.post(url, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          console.log(response.data);
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
          }
        }
      };
    
      const handlePostMessage = () => {
        postMessage();
      };

    const handleSelect = () =>{ 
         
        setDropState(!dropState); 
    }
    const handleSelectSec = () =>{ 
         
        setDropStateSec(!dropStateSec); 
    }
    const handleSelectThird = () =>{ 
         
        setDropStateThird(!dropStateThird); 
    }

    const calculateTotalDeposits = (array) => {
        // Use reduce method to iterate over the array and accumulate the sum of costs
        return array.reduce((totalCost, currentItem) => {
          // Add the cost of the current object to the total cost
          return totalCost + ((currentItem?.Price)*(currentItem?.count));
        }, 0); // Start with a total cost of 0
      };





      const getUsers = async () => {
        try {
            const response = await axiosPrivate.get('/Orders', {
                // signal: controller.signal,
                // params: {
                //   email: auth.user,
                //   AccountNo: auth.foundUser.AccountNo,
                //   org: 'value2',
                //   mgtLvl: 'value2',
                // }
            });
            
            // console.log("init tdata")

            if(response?.data){
                console.log(response)
                setTasks(response.data.propdata);
                // console.log("tasks",Tasks)
                // if(response.data?.propdata){
                //   console.log(response.data);
                //   console.log("Here");
                //   setTData(response?.data?.propdata);
                //   console.log("tdata",tData)

            }
            
          //   isMounted && setUsers(response.data);
        } catch (err) {
            console.error(err);
          //   navigate('/login', { state: { from: location }, replace: true });
        }
    }
 

    useEffect(() => {

      
  
    
        getUsers();
        console.log("tasks",Tasks)
  
      
    }, [])

    //Calculating price
    const CalculaErrandPrice = (calculatedDistance) =>{

        if(errandType == "Errand"){
           return (calculatedDistance*priceRate+ErrandFee>50) ? (calculatedDistance*priceRate+ErrandFee): 50

        }else{

            return (calculatedDistance*priceRate>30) ? (calculatedDistance*priceRate): 30

        }

      }
       


    // useEffect(()=>{
    //     // populateData()
    //     console.log("dadgagaddaa")

    // },[])

    async function handleSubmit(e) {  
    // async function registerUser(e) {  
        e.preventDefault(); 
     
        console.log("hii",SAddress,EAddress,carrier,Details,Collector)


        if(errandType == "Select Task Type"){
            alert("Please selec Task Type")
        }
        else 
        if(carrier == "Select Mode of Transport"){
            alert("Please select carrier type")
        }
        // else if(Collector == undefined){
        //     alert("Please pick collector details")
        // }
        else{  

            setLoading(true)
             ////
          const response = await axiosPrivate.post("/Orders",
          // const response = await axiosPrivate.post("http://localhost:2002/Orders",
             JSON.stringify({
                 SAddress,
                 EAddress,
                 carrier,
                 Details,
                 name: auth.auth.user,
                 PhoneNumber: auth.auth.foundUser.PhoneNumber,
                 startAddress,
                 endAddress,
                 duration, 
                //  SLat:"",
                //  SLng:"",
                //  ELat: "",
                //  ELng: "",
                 SLat:startCoords[0],
                 SLng:startCoords[1],
                 ELat:  endCoords[0],
                 ELng: endCoords[1],
                 distance,
                 duration, 
                 Collector,
                 cartItem,
                 Price : CalculaErrandPrice(distance/1000),
                // Price : ((distance/1000)*priceRate).toLocaleString(2)>30?((distance/1000)*priceRate).toLocaleString(2)>30:30,
                 splaceid,
                 dplaceid,
                 errandType,
                 priceRate
 
                 
 
 
                 
             
              }),
             // JSON.stringify({ user, pwd }),
             {
                 headers: { 'Content-Type': 'application/json',
                
              },
                 
                 withCredentials: true,
                 crossDomain: true,
                 
             }
         );
          ////
         console.log(response)
  
            

         
 
           if(response?.data?.status == 'Ok'){

            console.log("tesing")
            getUsers()
             // navigate("/login");
 
             setSAddress("");
             setEAddress("");
             setCarrier("");
             setDetails("");
             setDistance("");
             setDuration("");
             setStartAddress("");
             setEndAddress("");
             setSelected("Picking parcel yourself?"); 
             setCarrier("Select Mode of Transport");
 
             setmorders(false)
             setorders(true)
             
 
 
             setLoading(false);
             postMessage()
             // setSuccessMsg(true)
             
           }else{
           
             setLoading(false);
             // setMsg(response?.data?.msg)
             // setErrorMsg(true);
           }

        }
     

        
    }

    const fbnerd = "https://m.me/Nerdarr"
    const tsup = "https://wa.me/260776604969"
    const fbMillie = "https://m.me/ntumaizambia"
    const Linkedin = "https://www.linkedin.com/company/ntumai/"
    const Twitter = "https://x.com/Ntumaizambia"


    

    return <>
    <div className="orderWhole">
   
    <div className="TitleCover">
                    {/* <h1>MPILE TRUST</h1> */}
                </div>
                <div className="Options">
                    <div>
                    <button className={morders?"Active" :"Inactive"} onClick={()=>{
                        setmorders(true)
                        setorders(false)
                       
                    }}>Order Details</button>
                    <button

                        className= {orders? "Active" :""}       

                        onClick={()=>{

                           
                            setorders(true)
                            setmorders(false)

                            
                            
                        }}
                    
                    >Orders</button>
                    </div>
                </div>
     
        <div className="inputCover">
            {morders && <>

                      <form onSubmit={handleSubmit}>

        <div className="inputWhole">
            <div className="Label">
                <label>From</label>

            </div>
            {/* <div>
                <h1>{distance}</h1>
                <h1>{endAddress}</h1>
                <h1>{"Here"}</h1>
            </div> */}
            <div className="Input">
                <input

                      value={startAddress}
                      disabled
                    // onChange={(e) => setSAddress(e.target.value)}
                
                placeholder="Address"></input>
            </div>

        </div>
        <div className="inputWhole">
            <div className="Label">
                <label>To</label>

            </div>
            <div className="Input">
                <input 

                    value={endAddress}
                    disabled
                    // onChange={(e) => setEAddress(e.target.value)}
                placeholder="Address"></input>
            </div>
            {/* startAddress,
    endAddress,
    startCoords,
    endCoords,
    distance,
    duration, */}

        </div>
        <div className="inputWhole">
            <div className="Label">
                <label>Est. Distance</label>

            </div>
            <div className="Input">
                <input 
                required
                value={`${distance/1000} km`}
                disabled
                // onChange={(e) => setCarrier(e.target.value)}
                
                placeholder="Bike/Car"></input>
            </div>

        </div>
        <div className="inputWhole">
            <div className="Label">
                <label>Shopping Cart</label>

            </div>
            <div className="CartContainer">
                {cartItem.length? cartItem.map((item,index)=>{

                   cartSum = cartSum+(item.Price*item.count);
                     return(
                        <>  
                            <div className="cartCover">
                                 <div className="cartCount">
                                        <p> {index+1}.</p>
                                 </div>
                                 <div className="cartName">
                                      <p> {item.Name}</p>
                                </div>
                                  <div className="cartPrice">   
                                      <b><p> {`ZMK ${item.Price}(${item.count})`}</p></b>
                                 </div>
                                  {/* <div className="cartPrice">   
                                      <p> {item.count}</p>
                                 </div> */}
                            </div>
                           
                        </>
                     )
                }):
                <div> 
                    <b><p>No items in shopping cart</p></b>
                </div> }
                <div className="cartTotal">
                                    <div className="Lead">
                                          <b><p>Total</p></b>   
                                    </div>
                                    <div className="actualTotal">
                                       <b><p>{`ZMK ${cartSum}`}</p></b> 
                                    </div>
                            </div>
        
            </div>

        </div>
        <div className="inputWhole">
            <div className="Label">
                <label>Total Est. Price </label>
                {/* <label>Total Est. Price [5zmk/km]</label> */}

            </div>
            <div className="InputPrice">
                <input 
                value={`${(((CalculaErrandPrice(distance/1000)))+cartSum).toLocaleString(2)} ZMK`}
                //value={`${(((distance/1000)*priceRate)+cartSum).toLocaleString(2)} ZMK`}
                disabled
                // onChange={(e) => setCarrier(e.target.value)}
                
                placeholder="Bike/Car"></input>
            </div>

        </div>
       

        <div className="CInputs">
            {/* <label>Mode</label> */}
                
                <div className='inputGroupa'>  

                    {/* TRANSACTION INPUT FIELDS*/}

                    <div className='dropContainer'>

                   

                
                            <div className='categoryButton'  onClick={handleSelectThird} >

                                  <div> <p>{errandType}</p> </div>  
                                  <div> <FontAwesomeIcon icon={faChevronDown} className="FontAwesomeIcon"/></div>

                            </div>
                                   
                            { dropStateThird && <div className='dropDownList'>      
                                                                                     
                                                                   
                                                   { optionsThird.map((item,key)=>{
                                                   
                                                      return(
                                                            <div  name="Industry" 
                                                                                // onChange={handleChange} 
                                                                                value={setErrandType} onClick={e => {
                                                                                setErrandType(item);
                                                                                // setType(item);
                                                                                setDropStateThird(false);
                                                                                console.log("select",item)
                                                                              
                                                           
                                                            }}
                                                         
                                                         
                                                         
                                                         key={item} className='dropDownItem'>
                                                               <p>{item}</p>
                                                          </div>
                                                      )})        

                                                    }

                                           </div>                                                                                                                        

                                  }
                           

               

                    </div>

                   </div>
               

             </div>
        <div className="CInputs">
            {/* <label>Mode</label> */}
                
                <div className='inputGroupa'>  

                    {/* TRANSACTION INPUT FIELDS*/}

                    <div className='dropContainer'>

                   

                
                            <div className='categoryButton'  onClick={handleSelectSec} >

                                  <div> <p>{carrier}</p> </div>  
                                  <div> <FontAwesomeIcon icon={faChevronDown} className="FontAwesomeIcon"/></div>

                            </div>
                                   
                            { dropStateSec && <div className='dropDownList'>      
                                                                                     
                                                                   
                                                   { optionsSec.map((item,key)=>{
                                                   
                                                      return(
                                                            <div  name="Industry" 
                                                                                // onChange={handleChange} 
                                                                                value={carrier} onClick={e => {
                                                                                setCarrier(item);
                                                                                // setType(item);
                                                                                setDropStateSec(false);
                                                                                console.log("select",item)
                                                                              
                                                           
                                                            }}
                                                         
                                                         
                                                         
                                                         key={item} className='dropDownItem'>
                                                               <p>{item}</p>
                                                          </div>
                                                      )})        

                                                    }

                                           </div>                                                                                                                        

                                  }
                           

               

                    </div>

                   </div>
               

             </div>
        <div className="CInputs">
            {/* <label>Collector</label> */}
                
                <div className='inputGroupa'>  

                    {/* TRANSACTION INPUT FIELDS*/}

                    <div className='dropContainer'>

                   

                
                            <div className='categoryButton'  onClick={handleSelect} >

                                  <div> <p>{selected}</p> </div>  
                                  <div> <FontAwesomeIcon icon={faChevronDown} className="FontAwesomeIcon"/></div>

                            </div>
                                   
                            { dropState && <div className='dropDownList'>      
                                                                                     
                                                                   
                                                   { options.map((item,key)=>{
                                                   
                                                      return(
                                                            <div  name="Industry" 
                                                                                // onChange={handleChange} 
                                                                                value={selected} onClick={e => {
                                                                                setSelected(item);
                                                                                setType(item);
                                                                                setDropState(false);
                                                                                console.log("select",selected)
                                                                              
                                                           
                                                            }}
                                                         
                                                         
                                                         
                                                         key={item} className='dropDownItem'>
                                                               <p>{item}</p>
                                                          </div>
                                                      )})        

                                                    }

                                           </div>                                                                                                                        

                                  }
                           

               

                    </div>

                   </div>
               

             </div>



        {tidType =="No" && 

                <div className="inputWhole">
                        <div className="Label">
                            <label>Collector #</label>

                        </div>
                        <div className="Input">
                            <input 

                                value={Collector}
                                onChange={(e) => setCollector(e.target.value)}
                            
                            placeholder="Contact details"></input>
                        </div>

                </div>
        }
      
        <div className="inputWhole">
            <div className="Label">
                <label>Notes</label>

            </div>
            <div className="Input">
                <input 

                    value={Details}
                    onChange={(e) => setDetails(e.target.value)}
                
                placeholder="Add some cool notes here.."></input>
            </div>

        </div>

        <div className="inputWhole">
            
            <div className="Input btn">

                {Loading?   
                      <div className="orderloader">
                      <BeatLoader color="#08af97"/>
                    </div> :
                    <input type={"submit"} value="Submit Order" >
                   
                    </input>

                
            }
                
                  
                    
            </div>

        </div>


        </form>
                

            </>}
            <div className="TasksCover">    
                
            {orders&& Tasks && Tasks
             .filter((row)=>{
                // const date = new Date(row.TDate);
                // // Checks if the data is within the filter range
                if ((row.name==auth?.auth?.user)) {
                    
                    console.log("pass condition")
                    return row;
                }
                
              })
            .sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
            
            .map((index,key)=>{
                  var isod =  new Date(index.OrderDate)
                //   .toISOString;

                  
                return (
                    <>  
                        
                       
                            <div className="TasksSecond">   
                                <div className="item">
                                        <div className="Header">
                                            <p><b>Order #{`${(index._id).toString().substring(20, 31)}`}</b></p>
                                        </div>
                                        <div className="Item">
                                            <div className={index.Status=="Completed"? "CompleteStatus": "PendingStatus"}>
                                            {/* <div className="PendingStatus"> */}
                                                <p>{index.Status}</p>    

                                            </div>
                                    </div> 
                                </div>
                                <br/>
                                    {/* <div className="item">
                                        <div className="Heading">
                                            <b><p>client:</p></b>
                                        </div>
                                        <div className="Item">

                                            <p>{ auth.auth.foundUser.name}</p>    

                                        </div>
                                        

                                    </div> */}
                                    {/* <div className="item">
                                        <div className="Heading">
                                            <b><p>PhoneNumber:</p></b>
                                        </div>
                                        <div className="Item">

                                            <p>{auth.auth.foundUser.PhoneNumber}</p>    

                                        </div>
                                        

                                    </div> */}
                                    <div className="item">
                                        {/* <div className="Heading">
                                            <b><p>Date:</p></b>
                                        </div> */}
                                        <div className="Item">

                                            <p>{ isod.toString().substring(0, 15)}</p>    
                                            {/* <p>{ isod.toString().substring(2, 10)}</p>     */}

                                        </div>
                                        

                                    </div>
                                    {/* <div className="item">
                                        <div className="Heading">
                                            <b><p>Status:</p></b>
                                        </div>
                                        <div className="Item">
                                            <p>Pending</p>    

                                        </div>
                                        

                                    </div> */}
                                    <div className="item">
                                        {/* <div className="Heading">
                                            <b><p>Carrier:</p></b>
                                        </div> */}
                                        <div className="Item">
                                            <p>{index.carrier}</p>    

                                        </div>
                                        

                                    </div>
                                    <div className="item">
                                        <div className="Heading">
                                            <b><p className="classp" >From:</p></b>
                                        </div>
                                        <div className="Item">
                                            <p>{index.startAddress}</p>    

                                        </div>
                                        

                                    </div>
                                   
                                    <div className="item">
                                        <div className="Heading">
                                            <b><p className="classp" >To:</p></b>
                                        </div>
                                        <div className="Item">
                                            <p>{index.DAddress}</p>    

                                        </div>
                                        

                                    </div>

                                    <div className="item">
                                        <div className="Heading">
                                            <b><p className="classp" >Distance:</p></b>
                                        </div>
                                        <div className="Item">
                                            <p>{`${index?.distance} Meters`}</p>    

                                        </div>
                                        

                                    </div>
                                    <br/>
                                    <div className="item">
                                        {/* <div className="Heading">
                                            <b><p>Details:</p></b>
                                        </div>
                                        <div className="Item">
                                            <p>{index.Details}</p>    

                                        </div> */}
                                         <p><i><b>Notes:</b> *{index.Details}*</i></p>   
                                        

                                    </div>
                                    <br/>
                                    <div>
                                        {index?.Cart?.length>0 && index?.Cart.map((crtIem,crtIndex)=>{
                                        //   setCartPrices(calculateTotalDeposits(index.Cart)) 
                                              return(
                                                    <div> 
                                                        <div> 
                                                            <p><b>Shopping Cart</b></p>
                                                        </div>

                                                        <div className="wholecartCOver">   

                                                             <div className="index"> 
                                                                <p>{`${crtIndex+1}.`}</p>
                                                            </div>
                                                             <div className="name"> 
                                                                <p>{crtIem?.Name}</p>
                                                            </div>
                                                             <div className="CartCount"> 
                                                                 <p>{`(${crtIem?.count})`}</p>
                                                            </div>
                                                             <div className="CartPrice"> 
                                                               <p>{`zmk ${crtIem?.Price*(crtIem?.count)}`}</p>
                                                            </div>
                                                           

                                                        </div>

                                                        
                                                        
                                                       
                                                    </div>
                                              )
                                        })
                                        
                                        }
                                    </div>
                                    <br/>
                                    {/* <div className="item"> */}
                                        <div className="wholecartCOverSec">
                                            <div>
                                                <p><b>Grand Total</b></p>
                                            </div>
                                            <div>
                                                <b>{`ZMK ${((CalculaErrandPrice(index.distance/1000))+calculateTotalDeposits(index?.Cart)).toLocaleString(2)}`}</b>
                                                {/* <b>{`ZMK ${((index.distance/1000)*priceRate+calculateTotalDeposits(index?.Cart)).toLocaleString(2)}`}</b> */}

                                            </div>
                                        </div>
                                     
                                    {/* </div> */}
                                    {/* <p>**Support**</p> */}
                                    <br/>
                                    <br/>

                                    <div className="iconRow">
                   <div className="rowPart">
                         <div className="Footericons">
                            
                         <Link className='text-link' to={'tel:+260776604969'}><FontAwesomeIcon className="fontawesomIcons" icon={faPhone}> </FontAwesomeIcon><b>+260776604969</b> </Link>
                         <Link className='text-link' to={fbMillie}><FontAwesomeIcon className="fontawesomIcons" icon={faFacebook}> </FontAwesomeIcon> </Link>
                         <Link className='text-link' to={tsup}><FontAwesomeIcon className="fontawesomIcons" icon={faWhatsapp} /></Link> 
                              {/* <Link className='text-link' to={fbMillie}> <FontAwesomeIcon className="fontawesomIcons" icon={faFacebook} /></Link> */}
                              <Link className='text-link' to={Twitter}><FontAwesomeIcon className="fontawesomIcons" icon={faTwitter} /></Link> 
                              
                              {/* <Link className='text-link' to={fbMillie}><FontAwesomeIcon className="fontawesomIcons" icon={faInstagram} /></Link>    */}
                              <Link className='text-link' to={Linkedin}><FontAwesomeIcon className="fontawesomIcons" icon={faLinkedin} /></Link> 
                        </div>
                   </div>
                   {/* <div className="rowPart">
                        <p>2024 © Ntumai. All Rights Reserved.</p>
                   </div> */}
                
             </div>

                        
                           
                           
                        </div>
                      
                    
                    </>
                )
            })
             }

            </div>
           
          

        </div>
        
        
    </div>
   
        
    </>

}

export default OrderSteps;